/* eslint-disable @typescript-eslint/naming-convention */
const models = {
  account: {
    emailFrequency: {
      title: {
        daily_digest: "Daily digest",
        each_message: "Each email"
      }
    },
    emailSecurity: {
      description: {
        full: "Receive complete content, including attachments, directly in your inbox",
        private: "Receive notifications in your inbox without revealing message content for the most privacy",
        snippet: "Get a preview of your messages to easily prioritize communications"
      },
      mainDescription:
        "Manage your email preferences and customize the visibility of message content in your notification emails.",
      title: {
        full: "Full message",
        private: "Private",
        snippet: "Message preview"
      }
    }
  },
  actionItemCtas: {
    complete_consent_form: "Consent form",
    complete_legal_document_tracker: "Legal tracker",
    complete_medication_tracker: "Medications",
    complete_provider_list: "Providers"
  },
  actionItemCtasIncludes: {
    complete_consent_form: "Tool included: Consent form",
    complete_legal_document_tracker: "Assessment included: Legal document tracker",
    complete_medication_tracker: "Assessment included: Medications tracker",
    complete_provider_list: "Assessment included: Provider tracker"
  },
  activity: {
    activityKind: {
      all: "Active journeys ({count})",
      assessment_completed: "Assessments completed ({count})",
      consult_scheduled: "Sessions scheduled ({count})",
      content_viewed: "Content viewed ({count})"
    },
    actorKind: {
      all: "All",
      clients: "Members",
      experts: "Care Partners"
    },
    kindOptions: {
      article_saved: "Article saved",
      article_unsaved: "Article unsaved",
      article_video_played: "Video played",
      care_plan_action_item_added: "Member task added",
      care_plan_action_item_changed: "Member task updated",
      care_plan_action_item_deleted: "Member task deleted",
      care_plan_action_item_incompleted: "Member task marked incomplete",
      care_plan_lite_published: "Care Plan lite published",
      care_plan_opened: "Care Plan opened",
      care_team_invite_canceled: "Care team invite canceled",
      checklist_added: "Checklist added",
      checklist_changed: "Checklist changed",
      checklist_deleted: "Checklist deleted",
      checklist_item_added: "Checklist item added",
      checklist_item_changed: "Checklist item updated",
      checklist_item_completed: "Checklist item completed",
      checklist_item_deleted: "Checklist item deleted",
      checklist_item_incompleted: "Checklist item marked incomplete",
      client_visible_task_deleted: "Care Plan task deleted",
      client_visible_task_updated: "Care Plan task updated",
      consult_canceled: "Session canceled",
      consult_rescheduled: "Session rescheduled",
      consult_updated: "Session updated",
      expert_role_added: "Care Partner added to the journey",
      expert_role_deleted: "Care Partner removed from the journey",
      goal_updated: "Goal updated",
      journey_stage_changed: "Journey stage changed",
      resource_changed: "Resource updated",
      resource_deleted: "Resource deleted",
      resource_notes_changed: "Resource notes changed",
      signed_document_faxed: "Signed document faxed",
      signed_document_revoked: "Signed document revoked",
      signed_document_sent: "Signed document sent",
      task_canceled: "Client visible task canceled",
      task_uncanceled: "Client visible task uncanceled"
    },
    section: {
      CARE_PLAN: "Care Plan"
    },
    showDetails: "Show details",
    startTime: {
      all: "All",
      past_seven_days: "Past 7 days",
      past_thirty_days: "Past 30 days"
    }
  },
  activityFeed: {
    article_opened: '{fullName} viewed the article "{title}"',
    article_video_played: '{fullName} played the video "{title}"',
    assessment_completed: {
      legal_tracker_completed: "{fullName} completed legal document tracker",
      medication_tracker_completed: "{fullName} completed medication tracker",
      pme_completed: "{fullName} completed personalize my experience",
      provider_tracker_completed: "{fullName} completed provider tracker"
    },
    assessment_started: {
      legal_tracker_started: "{fullName} started legal document tracker",
      medication_tracker_started: "{fullName} started medication tracker",
      pme_started: "{fullName} started personalize my experience",
      provider_tracker_started: "{fullName} started provider tracker"
    },
    care_plan_action_item_added: '{fullName} added the action item "{title}"',
    care_plan_action_item_changed: '{fullName} edited the action item "{title}"',
    care_plan_action_item_completed: '{fullName} completed action item "{title}"',
    care_plan_action_item_deleted: '{fullName} removed the action item "{title}"',
    care_plan_action_item_incompleted: '{fullName} marked an action item as incomplete "{title}"',
    care_plan_goal_added: '{fullName} added a goal "{title}"',
    care_plan_goal_archived: '{fullName} archived the goal "{title}"',
    care_plan_goal_changed: '{fullName} edited the goal "{title}"',
    care_plan_goal_deleted: '{fullName} removed the goal "{title}"',
    care_plan_goal_priority_changed: '{fullName} changed "{title}" from {previousPriority} to {priority}',
    care_plan_goal_unarchived: '{fullName} unarchived the goal "{title}"',
    care_plan_lite_published: "{fullName} completed Care Plan Lite",
    care_plan_opened: "{fullName} viewed the Care Plan",
    care_plan_stage_changed: "{fullName} changed the stage from {previousStage} to {stage}",
    care_team_invite_canceled: "{fullName} removed {email} from the journey",
    care_team_invite_sent: "{fullName} added {email} to the journey",
    community_opened: "{fullName} opened the community",
    consult_canceled: "{fullName} cancelled a session {startTime}",
    consult_rescheduled: "{fullName} rescheduled a session from {previousStartTime} to {startTime}",
    consult_scheduled: "{fullName} scheduled a session for {startTime}",
    consult_updated: "{fullName} updated a session on {startTime}",
    employer_benefit_opened: "{fullName} opened {title} in marketplace",
    expert_role_added: "{fullName} joined the journey",
    expert_role_deleted: "{fullName} left the journey",
    forJourney: "for {journey}",
    goal_added: '{fullName} added the goal "{title}"',
    goal_deleted: '{fullName} removed the goal "{title}"',
    goal_updated: '{fullName} edited the goal "{title}"',
    resource_added: '{fullName} added the resource "{title}"',
    resource_changed: '{fullName} edited the resource "{title}"',
    resource_deleted: '{fullName} removed the resource group "{title}"',
    resource_group_added: '{fullName} added the resource group "{title}"',
    resource_group_changed: '{fullName} edited the resource group "{title}"',
    resource_group_deleted: '{fullName} removed the resource group "{title}"',
    resource_marked_not_useful: '{fullName} marked the resource "{title}" as not useful',
    resource_marked_useful: '{fullName} marked the resource "{title}" as useful',
    resource_notes_changed: '{fullName} added notes to the resource "{title}"',
    signed_document_completed: "{fullName} completed a consent form for {documentFor}",
    signed_document_faxed: "{fullName} faxed a consent form for {documentFor}",
    signed_document_revoked: "{fullName} revoked a consent form for {documentFor}",
    signed_document_sent: "{fullName} sent a consent form by email for {documentFor}",
    worksheet_added: '{fullName} added the worksheet "{title}"',
    worksheet_changed: '{fullName} changed the worksheet "{title}"',
    worksheet_completed: '{fullName} completed the worksheet "{title}"',
    worksheet_deleted: '{fullName} deleted the worksheet "{title}"',
    worksheet_incompleted: '{fullName} incompleted the worksheet "{title}"',
    worksheet_item_added: '{fullName} added the worksheet item "{title}"',
    worksheet_item_changed: '{fullName} changed the worksheet item "{title}"',
    worksheet_item_completed: '{fullName} selected the worksheet item "{title}"',
    worksheet_item_deleted: '{fullName} deleted the worksheet item "{title}"',
    worksheet_item_incompleted: '{fullName} deselected the worksheet item "{title}"'
  },
  agreement: {
    kind: {
      disclosure_agreement: "Disclosure agreement",
      marketing_agreement: "Marketing agreement",
      terms_and_conditions: "Terms and conditions"
    }
  },
  appEvent: {
    fieldChange: "{field} changed from {from} to {to}"
  },
  article: {
    contentType: {
      my_story: "MyStory",
      text: "Text",
      video: "Video",
      webinar: "Live event"
    },
    contentTypeFilters: {
      all: "All ({count})",
      text: "Articles ({count})",
      video: "Videos ({count})"
    },
    createmy_story: "Create MyStory",
    createtext: "Create article",
    createvideo: "Create article",
    createwebinar: "Create live event",
    geography: {
      international: "International",
      usa_specific: "USA specific"
    },
    registerNow: "Register now",
    relatedArticles: "Related articles",
    sources: "Sources",
    title: "Read {title}",
    webinarCompleted: {
      no: "No",
      yes: "Yes"
    },
    webinarRecurDescription: {
      dayOfMonth: "the {day} of the month",
      everyDay: "every day",
      everyMonth: "every month on",
      everyNDays: "every {count, plural, one {# day} other {# days}}",
      everyNMonths: "every {count, plural, one {# month} other {# months}} on",
      everyNWeeks: "every {count, plural, one {# week} other {# weeks}} on",
      everyWeek: "every week on",
      monthlyWeek: {
        FIRST_WEEK: "the first {weekday}",
        FOURTH_WEEK: "the fourth {weekday}",
        LAST_WEEK: "the last {weekday}",
        SECOND_WEEK: "the second {weekday}",
        THIRD_WEEK: "the third {weekday}"
      },
      repeats: "Repeats",
      repeatsNTimes: "{count, plural, one {# more time} other {# more times}}",
      weekdaysPlural: {
        FRIDAY: "Fridays",
        MONDAY: "Mondays",
        SATURDAY: "Saturdays",
        SUNDAY: "Sundays",
        THURSDAY: "Thursdays",
        TUESDAY: "Tuesdays",
        WEDNESDAY: "Wednesdays"
      }
    },
    webinarRecurMonthlyTypeOptions: {
      DAY_OF_MONTH: "On a specific date of each month (i.e. always on the 10th)",
      WEEK_AND_WEEKDAY: "On a specific week and weekday of the month (i.e. on the second Tuesday)"
    },
    webinarRecurMonthlyWeekOptions: {
      FIRST_WEEK: "First week",
      FOURTH_WEEK: "Fourth week",
      LAST_WEEK: "Last week",
      SECOND_WEEK: "Second week",
      THIRD_WEEK: "Third week"
    },
    webinarRecurTypeOptions: {
      DAILY: "Daily",
      MONTHLY: "Monthly",
      NONE: "Does not recur",
      WEEKLY: "Weekly"
    },
    webinarRecurWeekdayOptions: {
      FRIDAY: "Friday",
      MONDAY: "Monday",
      SATURDAY: "Saturday",
      SUNDAY: "Sunday",
      THURSDAY: "Thursday",
      TUESDAY: "Tuesday",
      WEDNESDAY: "Wednesday"
    },
    webinarRegistrationRecurrenceTypeOptions: {
      ALL_FUTURE_OCCURRENCES: "Register for this event and all recurring events",
      SINGLE_OCCURRENCE: "Register for this event only"
    }
  },
  articleShare: {
    clickToCopyFromAlert: "Click below to copy your link for sharing the article.",
    copyLink: "Copy link",
    linkCopied: "Link copied",
    share: "Share",
    shareArticle: "Share articles"
  },
  assessments: {
    goBack: "Go back",
    nextQuestion: "Next question"
  },
  automation: {
    action: "action",
    actionBlock: "Action",
    addAParent: "Add a parent",
    addActionBlock: "Add action",
    addAutomationAction: "Add an action",
    addAutomationCondition: "Add a condition",
    addConditionBlock: "Add condition",
    addTimingBlock: "Add timing",
    applicationCountLink: "{count, plural, one {# time} other {# times}}",
    applicationCountText: "The automation has been applied {link}",
    blockTypes: {
      terminator: "Exit"
    },
    condition: "condition",
    conditionBlock: "Condition",
    conditionFalseOutput: "NO",
    conditionTrueOutput: "YES",
    deleteBlock: "Delete block",
    draft: "DRAFT",
    editAutomationAction: "Edit action",
    editAutomationCondition: "Edit condition",
    live: "LIVE",
    outputs: "Outputs:",
    timing: "timing",
    timingBlock: "Time delay",
    triggerBlock: "Trigger"
  },
  automationActionBlock: {
    brokenReasons: {
      ARTICLE_MISSING:
        "This action is broken because it is connected to an article that does not exist. The automation will not run until this is corrected.",
      CARE_PLAN_TASK_TEMPLATE_MISSING:
        "This action is broken because it is connected to a task template that does not exist. The automation will not run until this is corrected.",
      GOAL_TEMPLATE_MISSING:
        "This action is broken because it is connected to a goal that does not exist. The automation will not run until this is corrected.",
      WORKSHEET_TEMPLATE_MISSING:
        "This action is broken because it is connected to a worksheet that does not exist. The automation will not run until this is corrected."
    },
    description: {
      APPLY_CARE_PLAN_TASK_TEMPLATE: 'Add the "{carePlanTaskTemplate}" task template to the Care Plan',
      APPLY_GOAL_TEMPLATE: 'Add the "{goalTemplate}" goal to the Care Plan',
      COMPLETE_ALL_TASKS_FROM_TEMPLATE: 'Complete all tasks created by "{carePlanTaskTemplate}"',
      COMPLETE_ALL_TASKS_OF_KIND: 'Complete all tasks of type "{taskKind}"',
      CREATE_MESSAGE: "Send a Care Partner-only message from the primary Care Partner",
      CREATE_PUSH_NOTIFICATION: "Send a push notification to {userType}",
      CREATE_TASK: 'Create {taskVisibility} task due in {taskDueDays} days at {taskDueHour} with title "{taskTitle}"',
      NO_OP: "Do nothing",
      RECOMMEND_AN_ARTICLE: 'Recommend the article "{articleTitle}"',
      RECOMMEND_A_WORKSHEET_TEMPLATE: 'Recommend the worksheet "{worksheetTitle}"'
    },
    kindOptions: {
      APPLY_CARE_PLAN_TASK_TEMPLATE: "Add a task template to the Care Plan",
      APPLY_GOAL_TEMPLATE: "Add a goal to the Care Plan",
      COMPLETE_ALL_TASKS_FROM_TEMPLATE: "Complete all tasks created by a template",
      COMPLETE_ALL_TASKS_OF_KIND: "Complete all tasks of a type",
      CREATE_MESSAGE: "Send a Care Partner-only message from the primary Care Partner",
      CREATE_PUSH_NOTIFICATION: "Send a push notification",
      CREATE_TASK: "Create a task",
      NO_OP: "Do nothing",
      RECOMMEND_AN_ARTICLE: "Recommend an article",
      RECOMMEND_A_WORKSHEET_TEMPLATE: "Recommend a worksheet"
    },
    limitedUsersDescription: {
      createPushNotification:
        "This action can apply to a limited list of users. If the trigger for this automation also contains this icon, the action will apply only to the list of users it outputs. Otherwise, it will apply to all users on the journey."
    },
    taskExpertOnly: "a Care Partner"
  },
  automationApplication: {
    status: {
      COMPLETED: "Complete",
      PAUSED: "Paused",
      STARTED: "Running"
    },
    tableHeadings: {
      actions: "Actions",
      date: "Start time",
      lovedOne: "Loved one",
      owner: "Owner",
      status: "Status"
    }
  },
  automationBlockApplication: {
    conditionEvaluationFalse: "Evaluated to: No",
    conditionEvaluationTrue: "Evaluated to: Yes",
    names: {
      action: "Action",
      condition: "Condition",
      timing: "Timing",
      trigger: "Trigger"
    },
    runTime: "Run time:",
    timingExecuteTime: "Pause end:",
    timingStartTime: "Pause start:",
    triggeredBy: "Triggered by: {actor}"
  },
  automationCondition: {
    kinds: {
      CARE_PLAN_IS_PUBLISHED: "The Care Plan is published",
      CARE_PROFILE_VALUE_IS: "An answer is selected in the care profile",
      FUTURE_CONSULT_SCHEDULED: "A session is scheduled for the future",
      JOURNEY_IS_ACTIVE: "The journey is active",
      JOURNEY_REQUESTED_LANGUAGE_INTERPRETATION: "The journey has requested language interpretation",
      MEMBER_MESSAGE_SINCE: "A member has sent a message since",
      NEVER: "Never true (use this to block execution)",
      OWNER_EMPLOYER_IS: "The journey owner's employer is",
      TIME_SINCE_LAST_CONSULT: "Time since the last session is at least"
    }
  },
  automationConditionBlock: {
    description: {
      conditionDescriptions: {
        CARE_PLAN_IS_PUBLISHED: '"the Care Plan is published"',
        CARE_PROFILE_VALUE_IS: '"in the care profile, {careProfileField} is {careProfileValues}"',
        FUTURE_CONSULT_SCHEDULED: '"a session is scheduled for the future"',
        JOURNEY_IS_ACTIVE: '"the journey is active"',
        JOURNEY_REQUESTED_LANGUAGE_INTERPRETATION: '"the journey requested language interpretation"',
        MEMBER_MESSAGE_SINCE: '"a member has sent a message in the last {duration} {durationUnits}"',
        NEVER: '"NEVER"',
        OWNER_EMPLOYER_IS: '"the journey owner\'s employer is {employerName}"',
        TIME_SINCE_LAST_CONSULT: '"it has been at least {duration} {durationUnits} since the last completed session"'
      },
      conditionGroupDescription: "({conditions})",
      conditionGroupJoin: " <strong>AND</strong> ",
      conditionGroupOperatorJoins: {
        AND: " <strong>AND</strong> ",
        OR: " <strong>OR</strong> "
      },
      noConditionGroups: "No conditions, will terminate the automation"
    }
  },
  automationConditionGroup: {
    operations: {
      AND: "AND",
      OR: "OR"
    }
  },
  automationTimingBlock: {
    durationOptions: {
      DAYS: "Days",
      HOURS: "Hours",
      MINUTES: "Minutes"
    },
    nodeDescription: "Wait {duration} {durationUnits}",
    nodeUnits: {
      DAYS: "{count, plural, one {day} other {days}}",
      HOURS: "{count, plural, one {hour} other {hours}}",
      MINUTES: "{count, plural, one {minute} other {minutes}}"
    }
  },
  automationTriggerBlock: {
    brokenReasons: {
      ARTICLE_DELETED:
        "This trigger is broken because it is connected to an article that does not exist. The automation will not run until this is corrected.",
      EMPLOYER_BENEFIT_DELETED:
        "This trigger is broken because it is connected to a marketplace benefit that does not exist. The automation will not run until this is corrected.",
      GOAL_TEMPLATE_ARCHIVED:
        "This trigger is broken because it is connected to a goal that does not exist. The automation will not run until this is corrected.",
      MISSING_CARE_PROFILE_FIELD:
        "This trigger used a care profile field that was removed. It will neeed to be updated to use a different field."
    },
    consultReportKindOptions: {
      ANY: "Any",
      CONSULT_REPORT: "Secondary session",
      INITIAL_CONSULT_REPORT: "Initial session"
    },
    description: {
      consultReportKindOptions: {
        ANY: "any session",
        CONSULT_REPORT: "a secondary session",
        INITIAL_CONSULT_REPORT: "an initial session"
      },
      format: "{frequency} when {userType} {kind}",
      formatWithoutUser: "{frequency} when {kind}",
      frequencies: {
        EVERY_TIME: "Every time",
        FIRST_TIME_ONLY: "The first time"
      },
      kinds: {
        ARTICLE_OPENED: 'opens the article "{articleTitle}"',
        ARTICLE_OPENED_ANY: "opens any article",
        CARE_PLAN_ACTION_ITEM_COMPLETED: "completes a member task",
        CARE_PLAN_PUBLISHED: "publishes the Care Plan",
        CARE_PROFILE_UPDATED: 'updates the care profile field "{careProfileField}"',
        CARE_PROFILE_UPDATED_WITH_VALUES: "{base} to {careProfileValues}",
        CARE_TEAM_INVITE_ACCEPTED: "accepts an invite to join the journey and creates an account",
        CARE_TEAM_INVITE_SENT: "invites someone to join the journey",
        CLIENT_LOCATION_CHANGED: "changes their location",
        CLIENT_RELATIONSHIP_CHANGED: "changes their relationship to the loved one",
        CONSULT_CANCELED: "cancels a session",
        CONSULT_REPORT_PUBLISHED: "finalizes {consultReportKind}",
        CONSULT_SCHEDULED: "schedules a session",
        EMPLOYER_BENEFIT_OPENED: "opens any marketplace item",
        FILE_RESOURCE_ADDED: "uploads a file to the documents section",
        FREE_TEXT_KEYWORD_MATCHED: "updates free text to match a keyword",
        GOAL_ADDED: 'adds the goal "{goalTemplate}"',
        GOAL_ADDED_ANY: "adds any goal",
        INITIAL_EXPERT_ASSIGNED: "assigns the initial primary Care Partner to the journey",
        JOURNEY_ADDED: "adds a new journey to the member account after onboarding",
        JOURNEY_TAG_ADDED: 'adds the tag "{tag}"',
        JOURNEY_TAG_ADDED_ANY: "adds any tag",
        LOSES_EXPERT_ACCESS_UPCOMING: "will lose Care Partner access in {duration} {durationUnits}",
        MESSAGE_SENT: "sends a message",
        NEVER: "Never (disable the automation)",
        PACKAGE_GRANT_ADDED: "receives a package refresh",
        PACKAGE_GRANT_EXPIRATION_UPCOMING: "will have a package expire in {duration} {durationUnits}",
        PACKAGE_GRANT_PURCHASED: "purchases a package",
        RECOMMENDED_NEXT_CONSULT_UPCOMING: "will have a recommended next session in {duration} {durationUnits}",
        RESOURCE_ADDED: "adds a published resource",
        RESOURCE_MARKED_NOT_USEFUL: "marks a resource as not useful",
        RESOURCE_MARKED_USEFUL: "marks a resource as useful",
        RESOURCE_PINNED: "pins a resource",
        RESOURCE_UNPINNED: "un-pins a resource",
        SIGNED_DOCUMENT_COMPLETED: "completes a signed document of type {signedDocumentKind}",
        TASK_ADDED: "adds {taskClientVisible} {taskKind}",
        TASK_COMPLETED: "completes {taskClientVisible} {taskKind}",
        TIME_LOG_ADDED: "logs time",
        TIME_SINCE_LAST_CONSULT: "it has been {duration} {durationUnits} since the last completed session",
        TRACKER_COMPLETED: "completes the {trackerType}",
        TRACKER_STARTED: "opens the {trackerType}",
        WORKSHEET_COMPLETED: "completes a worksheet",
        WORKSHEET_INCOMPLETED: "changes a worksheet from complete to incomplete",
        WORKSHEET_STARTED: "starts a worksheet"
      },
      never: "The automation is disabled",
      taskClientVisible: {
        ANY_TASK: "any task",
        CLIENT_VISIBLE: "a client-visible task",
        EXPERT_ONLY: "a Care Partner-only task"
      },
      taskKind: 'of type "{taskKind}"',
      taskKindAny: "of any type",
      userTypes: {
        ANYONE: "anyone on the journey",
        CLIENT_ANY: "any member on the journey",
        CLIENT_OWNER: "the primary benefit holder",
        EXPERT_ANY: "any Care Partner",
        EXPERT_PRIMARY: "the primary Care Partner on the journey"
      }
    },
    frequencyOptions: {
      EVERY_TIME: "Every time",
      FIRST_TIME_ONLY: "Only on the first time for each journey"
    },
    kindOptions: {
      ARTICLE_OPENED: "An article is opened",
      CARE_PLAN_ACTION_ITEM_COMPLETED: "A member task is completed",
      CARE_PLAN_PUBLISHED: "The Care Plan is published",
      CARE_PROFILE_UPDATED: "The care profile is updated",
      CARE_TEAM_INVITE_ACCEPTED: "An invitee accepts the invite and joins the journey",
      CARE_TEAM_INVITE_SENT: "Someone is invited to join the journey",
      CLIENT_LOCATION_CHANGED: "A member changes their location",
      CLIENT_RELATIONSHIP_CHANGED: "A member changes their relationship to the loved one",
      CONSULT_CANCELED: "A session is canceled",
      CONSULT_REPORT_PUBLISHED: "A session is finalized",
      CONSULT_SCHEDULED: "A session is scheduled",
      EMPLOYER_BENEFIT_OPENED: "A marketplace item is opened",
      FILE_RESOURCE_ADDED: "A file is uploaded to the documents section",
      FREE_TEXT_KEYWORD_MATCHED: "Keyword matches in free text",
      GOAL_ADDED: "A goal is added to the Care Plan",
      INITIAL_EXPERT_ASSIGNED: "The initial Care Partner is assigned to a journey",
      JOURNEY_ADDED: "A new journey is added to the account after onboarding",
      JOURNEY_TAG_ADDED: "A tag is added",
      LOSES_EXPERT_ACCESS_UPCOMING: "The journey will lose Care Partner access",
      MESSAGE_SENT: "A message is sent",
      NEVER: "Never (disable the automation)",
      PACKAGE_GRANT_ADDED: "A package refresh is received",
      PACKAGE_GRANT_EXPIRATION_UPCOMING: "One of the member's packages will be expiring",
      PACKAGE_GRANT_PURCHASED: "A package is purchased by the member",
      RECOMMENDED_NEXT_CONSULT_UPCOMING: "The journey will have a recommended next session",
      RESOURCE_ADDED: "A published resource is added to the Care Plan",
      RESOURCE_MARKED_NOT_USEFUL: "A resource is marked as not useful",
      RESOURCE_MARKED_USEFUL: "A resource is marked as useful",
      RESOURCE_PINNED: "A resource is pinned",
      RESOURCE_UNPINNED: "A resource is unpinned",
      SIGNED_DOCUMENT_COMPLETED: "A signed document is completed",
      TASK_ADDED: "A Care Partner task is added",
      TASK_COMPLETED: "A Care Partner task is completed",
      TIME_LOG_ADDED: "Time is logged",
      TIME_SINCE_LAST_CONSULT: "Time passed since the last completed session",
      TRACKER_COMPLETED: "A tracker is completed",
      TRACKER_STARTED: "A tracker is opened",
      WORKSHEET_COMPLETED: "A worksheet is completed",
      WORKSHEET_INCOMPLETED: "A worksheet changes from complete to incomplete",
      WORKSHEET_STARTED: "A worksheet is started"
    },
    limitedUsersDescription: {
      messageSent:
        "This trigger produces a limited list of users, the recipients of the message. Actions that include this icon will be applied only to those users. Other actions will be applied to the entire journey."
    },
    userTypeOptions: {
      ANYONE: "Anyone",
      CLIENT_ANY: "Any member on the journey",
      CLIENT_OWNER: "The primary benefit holder",
      EXPERT_ANY: "Any Care Partner",
      EXPERT_PRIMARY: "The primary Care Partner on the journey"
    }
  },
  carePlan: {
    allView: {
      expert_services: "Care Partner services",
      goals: "Goals",
      tools: "Tools"
    },
    status: {
      IN_PROGRESS: "In progress",
      NOT_STARTED: "Not started",
      ON_TIME: "On time",
      OVERDUE: "Overdue",
      UNPUBLISHED_CHANGES: "Unpublished changes"
    },
    timePeriods: {
      all_time: "All time",
      custom: "Custom",
      last_month: "Last month",
      last_week: "Last week",
      this_week: "This week",
      today: "Today"
    }
  },
  carePlanActionItem: {
    addClientTask: "Add next step",
    assignee: {
      client: "{assignee}",
      expert: "{assignee} (Care Partner)"
    },
    deleteTask: "Delete task",
    editClientTask: "Edit task"
  },
  careProfile: {
    acuity: "Acuity",
    acuityOptions: {
      CHRONIC: "Chronic",
      COMPLEX: "Complex",
      DAILY_NEEDS: "Daily needs",
      EPISODIC: "Episodic"
    },
    addNewDiagnosis: "Add new diagnosis",
    addNewGuardianLegalDocument: "Add parent/guardian legal document",
    addNewLegalDocument: "Add legal document",
    addNewProvider: "Add new provider",
    age: "How old is your loved one?",
    ageOptions: {
      deceased: "They're deceased",
      eighteen_to_sixty_four: "18-64",
      eighteen_to_twenty_four: "18-24",
      fifteen_to_seventeen: "15-17",
      four_to_five: "4-5",
      one_to_three: "1-3",
      six_to_eleven: "6-11",
      sixty_five_and_older: "65 years and older",
      twelve_to_fourteen: "12-14",
      twenty_five_to_sixty_four: "25-64",
      under_eighteen: "Under 18",
      under_one: "Under 1"
    },
    carePhaseOptions: {
      ACTIVELY_CARING: "Supporting current care needs",
      PLANNING_CARE: "Planning for future care needs"
    },
    careTypesProvidedOptions: {
      ADLS_AND_HANDS_ON_CARE: "ADLs and hands-on care",
      FINANCIAL: "Financial management and bill pay",
      HOSPITAL_AT_HOME: "Hospital at home",
      I_ADLS: "I-ADLs",
      MEDICAL_CARE: "Medical care and medication management",
      TRANSPORTATION: "Transportation",
      other: "Other"
    },
    caregiverTypes: "Who else is involved in helping your loved one?",
    caregiverTypesOptions: {
      caregivers: "Paid caregivers",
      children: "Children",
      friends: "Friends",
      grandparents: "Grandparents",
      neighbors: "Neighbors",
      other: "Other (fill in)",
      parents: "Parents",
      relatives: "Relatives",
      siblings: "Siblings",
      spouse: "Spouse",
      step_parents: "Step-parents"
    },
    caregivingDurationOptions: {
      FOUR_TO_FIVE_YEARS: "4-5 years",
      LESS_THAN_ONE_YEAR: "Less than 1 year",
      ONE_TO_TWO_YEARS: "1-2 years",
      SIX_OR_MORE_YEARS: "6+ years",
      TWO_TO_THREE_YEARS: "2-3 years"
    },
    caregivingImpactOptions: {
      HIGH: "High",
      LOW: "Low",
      MEDIUM: "Medium",
      NONE: "None",
      UNKNOWN: "Unknown"
    },
    caregivingTimePerWeekOptions: {
      ELEVEN_TO_TWENTY_HOURS: "11-20 hours",
      FIVE_TO_TEN_HOURS: "5-10 hours",
      FOUR_OR_MORE_HOURS: "4 or more hours",
      IT_DEPENDS: "It depends",
      LESS_THAN_ONE_HOUR: "Less than 1 hour",
      LESS_THAN_TWO_HOURS: "Less than 2 hours",
      ONE_TO_TWO_HOURS: "1-2 hours",
      TWENTY_TO_THIRTY_HOURS: "20-30 hours",
      TWO_TO_FOUR_HOURS: "2-4 hours"
    },
    cognitiveStatuses: "Do you have any concerns about your loved one's cognition?",
    cognitiveStatusesOptions: {
      forgetful: "Forgetful",
      long_term_memory_loss: "Long-term memory loss",
      none: "No concerns",
      short_term_memory_loss: "Short-term memory loss"
    },
    concern: {
      financial: "Financial",
      legal: "Legal",
      medical: "Medical",
      mental_health: "Mental health",
      none: "None",
      nonmedical_care: "Non-medical care",
      other: "Other"
    },
    conservatorshipStatusOptions: {
      CONSERVED: "Conserved",
      NOT_ADDRESSED: "Not addressed",
      NOT_CONSERVED: "Not conserved"
    },
    currentCareTransitions: "Is your loved one currently or imminently transitioning care? (choose all that apply)",
    currentCareTransitionsOptions: {
      adding_full_time_care: "Adding full-time care in the home (30+ hours)",
      adding_part_time_care: "Adding part-time care in the home (less than 30 hours)",
      going_home: "Going home from a medical facility",
      moving: "Moving to a new home or other living environment",
      none: "None",
      other: "Other",
      reducing_care: "Reducing care in the home",
      transferring: "Transferring between medical facilities"
    },
    currentSupport: {
      family_and_friends: "Help from family or friends",
      home_health_care: "Home health care (ex. visits from a nurse)",
      hospice: "Hospice",
      housekeeper_or_gardener: "Housekeeper / gardener",
      none: "None",
      paid_caregivers: "Paid caregivers or facility staff"
    },
    developmentalOrEducationalConcernsOptions: {
      ADHD: "ADHD/ADD",
      AUTISM_SPECTRUM_DISORDER: "Autism spectrum disorder",
      CEREBRAL_PALSY: "Cerebral palsy",
      DEVELOPMENTAL_CONCERN: "Developmental concern",
      DYSLEXIA: "Dyslexia",
      HEARING_IMPAIRMENT: "Hearing impairment",
      LANGUAGE_OR_SPEECH_DISORDER: "Language and speech disorder",
      LEARNING_CONCERN: "Learning concern",
      TOURETTE_SYNDROME: "Tourette syndrome",
      UNKNOWN: "Unknown",
      VISION_IMPAIRMENT: "Vision impairment",
      none: "None",
      other: "Other"
    },
    distanceToLovedOneOptions: {
      DIFFERENT_COUNTRY: "Different country",
      SAME_CITY: "Same city",
      SAME_COUNTRY: "Same country",
      SAME_HOUSEHOLD: "Same household",
      SAME_STATE: "Same state"
    },
    durableMedicalEquipmentOptions: {
      BLOOD_SUGAR_METER: "Blood sugar meter",
      COMMODE: "Commode",
      CPAP: "CPAP",
      FEEDING_TUBE: "Feeding tube",
      HOSPITAL_BED: "Hospital bed",
      INFUSION_SUPPLIES: "Infusion supplies",
      OXYGEN: "Oxygen",
      PATIENT_LIFT: "Patient lifts"
    },
    editAreasOfConcern: "Edit areas of concern",
    editBasicInfo: "Edit basic information",
    editCareProfile: "Edit care profile",
    editClientInfo: "Edit member info",
    editCurrentSituation: "Edit current situation",
    editGuidanceType: "Edit guidance type",
    editLanguageInterpretation: "Edit interpretation settings",
    editNameRelationship: "Edit {name}'s relationship",
    editNotes: "Edit notes",
    editRelationship: "Edit your relationship",
    editUrgency: "Edit urgency",
    emotionalConcerns: "Do you have any worries about your loved one's emotional well-being?",
    emotionalConcernsOptions: {
      anxiety: "Anxiety",
      depression: "Depression",
      isolation: "Isolation",
      none: "None",
      other: "Other",
      sleep_problems: "Excessive sleep or not enough sleep",
      weight_changes: "Excessive weight loss or gain"
    },
    employmentStatusOptions: {
      CONTRACTOR: "Contractor",
      EMPLOYED_FULL_TIME: "Employed full-time",
      EMPLOYED_PART_TIME: "Employed part-time",
      NOT_IN_WORKFORCE: "Not in the workforce",
      SEARCHING_FOR_WORK: "Searching for work",
      SELF_EMPLOYED: "Self-employed",
      TEMPORARY: "Temporary",
      VOLUNTEER: "Volunteer"
    },
    emptyStringValue: "(empty string)",
    everBeenCaregiverOptions: {
      NO: "No",
      NOT_SURE: "Not sure",
      YES: "Yes"
    },
    fallCount: "How many times has your loved one fallen in the last twelve months?",
    fallCountOptions: {
      none: "None",
      three_or_more: "3 or more",
      two_or_fewer: "2 or fewer"
    },
    financialManager: "Who manages your loved one's finances and pays their bills?",
    financialManagerOptions: {
      fiduciary: "Their fiduciary",
      loved_one: "Themself",
      other: "Someone else",
      relative: "Their relative",
      unsure: "Unsure"
    },
    guardianTypeOptions: {
      EMANCIPATED: "Emancipated",
      LEGAL_GUARDIAN: "Legal guardian",
      PARENT: "Parent"
    },
    guidanceType: "What type of guidance do you need?",
    guidanceTypeOptions: {
      EXPERT_GUIDED: "Care Partner guidance",
      SELF_GUIDED: "Self-guided research"
    },
    healthConcern: {
      cancer: "Cancer",
      copd: "Chronic Obstructive Pulmonary Disease (COPD)",
      dementia: "Alzheimer's or dementia",
      diabetes: "Diabetes",
      heart_conditions: "Heart conditions",
      kidney_conditions: "Kidney conditions",
      lung_conditions: "Lung condition",
      mental_health_conditions: "Mental health condition",
      none: "None",
      parkinsons: "Parkinson's",
      stroke: "Stroke"
    },
    healthInsuranceTypes: "What type of health insurance does your loved one have?",
    healthInsuranceTypesOptions: {
      medicaid: "Medicaid",
      medicare: "Medicare",
      none: "None",
      other: "Other",
      private: "Private / Employer-sponsored",
      supplemental: "Supplemental",
      veteran: "Veterans health care"
    },
    householdIncomeLevelOptions: {
      ABOVE_AVERAGE: "Above average income",
      AVERAGE: "Average income",
      BELOW_AVERAGE: "Below average income"
    },
    immigrationStatusOptions: {
      CITIZEN: "Citizen",
      CONDITIONAL_RESIDENT: "Conditional resident",
      NON_IMMIGRANT_OR_WORK_VISA: "Non-immigrant or work visa",
      PERMANENT_RESIDENT: "Permanent resident",
      UNDOCUMENTED: "Undocumented"
    },
    incomeSourcesOptions: {
      GOVERNMENT_PROGRAMS_OR_BENEFITS: "Government programs or benefits",
      INVESTMENTS_AND_SAVINGS: "Investments and savings",
      WAGES: "Wages"
    },
    insuranceTypes: "Does your loved one have any other types of insurance?",
    insuranceTypesOptions: {
      life_insurance: "Life insurance",
      long_term_care_insurance: "Long term care insurance",
      none: "None",
      other: "Other"
    },
    isVeteranOptions: {
      no: "Not a veteran",
      yes: "Veteran"
    },
    languageInterpretationNeededYes: "Yes, {language}",
    legalHelpNeeded: "Assistance required with legal docs?",
    legalHelpNeededOptions: {
      no: "No",
      yes: "Yes"
    },
    maritalStatus: "What is the loved one's marital status?",
    maritalStatusOptions: {
      divorced: "Divorced",
      domestic_partner: "Domestic partner",
      married: "Married",
      other: "Other",
      separated: "Separated",
      single: "Single",
      widowed: "Widowed"
    },
    medicationManagementStatusOptions: {
      NEEDS_ASSISTANCE: "Needs assistance",
      NOT_ADDRESSED: "Not addressed",
      NO_CONCERNS: "No medication management concerns",
      NO_MEDICATION_PRESCRIBED: "No medication prescribed"
    },
    medicationsAffordable: "Are your loved one's medications affordable?",
    medicationsAffordableOptions: {
      i_dont_know: "I don't know",
      no: "No",
      yes: "Yes"
    },
    name: "What is the loved one's name?",
    namePlaceholder: "First Last",
    pronounsOptions: {
      DO_NOT_USE_PRONOUNS: "Just my name please!",
      HE_HIM_HIS: "he/him/his",
      IT_IT_ITS: "it/it/its",
      OTHER: "Other (free text)",
      PER_PER_PERS: "per/per/pers",
      SHE_HER_HERS: "she/her/hers",
      THEY_THEM_THEIRS: "they/them/theirs",
      ZE_HIR_HIR: "ze/hir/hir"
    },
    providerEmotionalState: "Today, how are you feeling emotionally about helping to care for your loved one?",
    providerEmotionalStateOptions: {
      negative: "Negative",
      neutral: "Neutral",
      positive: "Positive",
      very_negative: "Very negative",
      very_positive: "Very positive"
    },
    providerFeelsSupportedOptions: {
      its_complicated: "It's complicated",
      no: "No",
      yes: "Yes"
    },
    providerFinancialConcerns: "Concerns about finances",
    providerFinancialConcernsOptions: {
      NOT_ADDRESSED: "Not addressed",
      no: "No",
      other: "Other",
      yes: "Yes"
    },
    range: {
      extremely: "5 (extremely) out of 5",
      level_2: "2 (negative) out of 5",
      level_4: "4 (positive) out of 5",
      neutral: "3 (neutral) out of 5",
      not_at_all: "1 (not at all) out of 5"
    },
    recentHospitalization: "Has your loved one been hospitalized or gone to the emergency room in the last six months?",
    recentHospitalizationOptions: {
      no: "No",
      yes: "Yes"
    },
    religion: "Does your loved one have a religious affiliation?",
    religionOptions: {
      atheism: "Atheism",
      bahai: "Baha'i",
      buddhism: "Buddhism",
      catholicism: "Catholicism",
      christianity: "Christianity",
      hinduism: "Hinduism",
      islam: "Islam",
      jainism: "Jainism",
      jehovahs_witness: "Jehovah's Witness",
      judaism: "Judaism",
      mormonism: "Mormonism",
      none: "None",
      other: "Other",
      paganism: "Paganism",
      rastafari: "Rastafari",
      santeria: "Santeria",
      shinto: "Shinto",
      sikhism: "Sikhism",
      spiritualism: "Spiritualism",
      taoism: "Taoism",
      unitarianism: "Unitarianism",
      zoroastrianism: "Zoroastrianism"
    },
    residenceType: "Where does your loved one live?",
    residenceTypeOptions: {
      assisted_living_community: "Assisted living community or care home",
      foster_care: "Foster care",
      group_home: "Group home",
      home_alone: "Alone at home",
      home_with_family: "At home with family",
      home_with_others: "At home with roommates or others",
      independent_living_community: "Independent living community",
      memory_care_community: "Memory care community",
      other: "Other",
      skilled_nursing_facility: "Skilled nursing facility (custodial)",
      unhoused: "Unhoused"
    },
    senseOfFulfillment: "LO sense of fulfillment",
    senseOfFulfillmentOptions: {
      NOT_ADDRESSED: "Not addressed",
      extremely: "Very positive",
      level_2: "Negative",
      level_4: "Positive",
      neutral: "Neutral",
      not_at_all: "Very negative"
    },
    severityOptions: {
      MILD: "Mild",
      MODERATE: "Moderate",
      SEVERE: "Severe",
      none: "None"
    },
    socialSatisfaction: "LO satisfied with social connection",
    socialSatisfactionOptions: {
      NOT_ADDRESSED: "Not addressed",
      extremely: "Very positive",
      level_2: "Negative",
      level_4: "Positive",
      neutral: "Neutral",
      not_at_all: "Very negative"
    },
    stabilitySupports: "Does your loved one hold anything for mobility assistance? (select all that apply)",
    stabilitySupportsOptions: {
      cane_or_walker: "Cane or walker",
      none: "No",
      objects: "Furniture, walls, or other object(s)",
      wheelchair: "Wheelchair"
    },
    stageLimited: {
      dependent: "Yes",
      independent: "No",
      interdependent: "Sometimes"
    },
    typesOfSupportNeeded: "Types of support from Grayce",
    typesOfSupportNeededOptions: {
      COMMUNITY: "Community groups",
      EDUCATION: "Education and discovery",
      EMOTIONAL_SUPPORT: "Emotional support",
      EXPERT_GUIDANCE: "Expert guidance",
      PLANNING_CARE: "Planning care",
      VETTING_SERVICES: "Vetting services"
    },
    updateDiagnosis: "Update diagnosis",
    updateGuardianLegalDocument: "Update parent/guardian legal document",
    updateLegalDocument: "Update legal document",
    updateProvider: "Update provider",
    urgency: "How soon do you need support?",
    urgencyOptions: {
      asap: "As soon as possible",
      just_browsing: "I just want to browse",
      soon: "Soon, but not urgent"
    },
    useCase: "Use case",
    useCaseNotSet: "Not set",
    useCaseOptions: {
      ADULT: "Caring for an adult",
      AGING_LOVED_ONE: "Caring for an aging loved one",
      CHILD: "Caring for a child (1-17 years)",
      GRIEVING: "Grieving a loss",
      INFANT: "Caring for an infant (0-12 months)",
      PREGNANCY: "Pregnancy",
      self: "Caring for myself"
    },
    vaConnected: "VA-connected",
    vaConnectedOptions: {
      no: "No",
      yes: "Yes"
    },
    yesNoDontKnowOptions: {
      i_dont_know: "Not sure",
      no: "No",
      yes: "Yes"
    }
  },
  careProfileChange: {
    added: {
      Diagnosis: "Added a medical diagnosis",
      Email: "Added an email address",
      LegalDocument: "Added a legal document",
      Medication: "Added a medication",
      Provider: "Added a provider"
    },
    changedFrom: "Changed from",
    deleted: {
      Diagnosis: "Deleted a medical diagnosis",
      Email: "Deleted an email address",
      LegalDocument: "Deleted a legal document",
      Medication: "Deleted a medication",
      Provider: "Deleted a provider"
    },
    diagnosisChangeDescription: "{field} for diagnosis of {condition}",
    emailAddressChangeDescription: "Email for {name}",
    emailAttributes: {
      email: "Address changed to"
    },
    forName: "for {name}",
    legalDocumentChangeDescription: "{field} for the legal document {kind}",
    locationChangeDescription: "Location for {name}",
    medicationChangeDescription: "{field} for the medication {name}",
    modelAttributes: {
      Diagnosis: {
        condition: "Condition",
        condition_other: "Other condition",
        date: "Date of diagnosis",
        severity: "Severity"
      }
    },
    phoneNumberAttributes: {
      formattedNumber: "Phone number changed to"
    },
    phoneNumberChangeDescription: "Phone number for {name}",
    providerChangeDescription: "{field} for the provider {name}",
    to: "to"
  },
  client: {
    attributes: {
      agreements: "Agreements",
      assessmentDate: "Assessment",
      dateOfBirth: "Date of birth",
      eligibility: {
        label: "Eligibility"
      },
      email: "Email",
      employer: "Official employer",
      enteredEmployer: "Member entered employer",
      expert: "Care Partner",
      fullName: "Member",
      timeBalance: "Hour balance"
    },
    banners: {
      EMAIL_VERIFIED: "Thank you for verifying your email. Your account setup is complete.",
      GATHERPALOOZA: "Join us for Gatherpalooza: your passport to a week of fun, prizes, and connection! 🌟",
      IMMINENT_WEBINAR: "Imminent webinar placeholder",
      LOW_BALANCE: "Hi {firstName}. Your account balance is getting low.",
      SCHEDULE_INITIAL_CONSULT: "Hi {firstName}. Book your first session to lighten your load.",
      SCHEDULE_NEXT_CONSULT:
        "Hi {firstName}. It's been a while since you met with {expertFirstName}. Schedule a check-in.",
      UPCOMING_CONSULT: "Hi {firstName}. You have a session that starts at {time}.",
      VERIFY_EMAIL: "Hi {firstName}. Looks like we still need you to verify your email.",
      WORK_EMAIL_VERIFICATION_NEEDED:
        "You're eligible for more Grayce benefits. You can claim them by verifying your work email address. We've sent you an email to your work email address, but if you'd like to resend it you can click the button to the right.",
      imminentWebinar: {
        registered: {
          alreadyStarted:
            'The "{webinarTitle}" Online event that you registered for started at {time}, but there\'s still time to join in. Click the button to the right to join your meeting.',
          notYetStarted:
            'The "{webinarTitle}" Online event that you registered for starts at {time}. Click the button to the right to join your meeting.'
        },
        unregistered: {
          alreadyStarted:
            'The "{webinarTitle}" Online event started at {time}, but there\'s still time to join in. Click the button to the right to view the event details and register to join.',
          notYetStarted:
            'The "{webinarTitle}" Online event starts at {time}. Click the button to the right to view the event details and register to join.'
        }
      },
      joinNow: "Join now",
      learnMore: "Learn more",
      registerNow: "Register now",
      subText: {
        gatherpalooza: "Happening March 28th - April 5th, 2024"
      },
      viewAccount: "View account"
    },
    changePassword: "Change password",
    editAccount: "Edit account",
    everBeenCaregiver: {
      NO: "No",
      NOT_SURE: "Not sure",
      YES: "Yes"
    },
    followupConsult: {
      remindMeLater: "Remind me again later",
      scheduleConsult: "Schedule session",
      stillNeedToSchedule:
        "Hey {name}, looks like you still need to schedule your follow-up session. Your Grayce Care Partner has recommended that you have your next session by {nextConsultDate}."
    },
    inviteAccountMembers: {
      consultTitle: "Add someone to this session",
      description: "Invite someone to join this care journey in caring for {name}.",
      reallyDelete: "Are you sure you want to withdraw the invitation to {email}?",
      title: "Add someone to this journey",
      withdraw: "Withdraw"
    },
    legalDocument: {
      dateUpdated: "Date updated",
      document: "Document",
      status: "Status"
    },
    medication: {
      addNewMedication: "Add new medication",
      addover_the_counter: "Add over-the-counter medication",
      addprescription: "Add prescription medication",
      addvitamin_or_supplement: "Add vitamin or supplement",
      editover_the_counter: "Edit over-the-counter medication",
      editprescription: "Edit prescription medication",
      editvitamin_or_supplement: "Edit vitamin or supplement",
      updateMedication: "Update medication"
    },
    provider: {
      addother_care_provider: "Add other care provider",
      addother_doctor: "Add other doctor",
      addprimary_care_provider: "Add primary care provider",
      editother_care_provider: "Edit other care provider",
      editother_doctor: "Edit other doctor",
      editprimary_care_provider: "Edit primary care provider"
    },
    topicOfInterest: {
      ADULT: "Caring for an adult or myself",
      AGING: "Caring for an aging loved one",
      CHILD: "Caring for a child",
      GRIEVING: "Grieving a loss",
      PREGNANCY: "Pregnancy or caring for an infant",
      UNKNOWN: "Unknown"
    },
    verifyEmail: {
      contactSupport: "If you did not receive an email, contact {support}.",
      emailSent: "An email was sent to {email}. Please verify your account by clicking on the link in that email.",
      send: "Resend email verification",
      sendWork: "Resend work email verification",
      support: "support@withgrayce.com",
      title: "Check your email",
      update: "update your email",
      updateEmail: "If your email address is incorrect, {update} in your account."
    },
    verifyWorkEmailForRefresh: {
      send: "Resend email verification"
    }
  },
  clientDeletion: {
    firstName: "Deleted",
    fullName: "Deleted User",
    lastName: "User"
  },
  clientRole: {
    relationship: {
      child: "My child",
      child_of_friend_or_relative: "Child of my friend or relative",
      domestic_partner: "My domestic partner",
      foster_child: "My foster child",
      friend: "My friend",
      grandchild: "My grandchild",
      grandparent: "My grandparent",
      myself: "Myself",
      niece_or_nephew: "My niece or nephew",
      other: "Other",
      parent: "My parent",
      parent_in_law: "My parent-in-law",
      relative: "My relative",
      sibling: "My sibling",
      spouse: "My spouse"
    },
    relationshipForHome: {
      child: "child's",
      child_of_friend_or_relative: "friend or relative's child's",
      domestic_partner: "domestic partner's",
      foster_child: "foster child's",
      friend: "friend's",
      grandchild: "grandchild's",
      grandparent: "grandparent's",
      niece_or_nephew: "niece or nephew's",
      other: "loved one's",
      parent: "parent's",
      parent_in_law: "parent-in-law's",
      relative: "loved one's",
      sibling: "sibling's",
      spouse: "spouse's"
    },
    relationshipForViewer: {
      child: "a parent",
      friend: "a friend",
      grandchild: "a grandparent",
      grandparent: "a grandchild",
      myself: "here for myself",
      other: "other",
      parent: "a child",
      parent_in_law: "a child-in-law",
      relative: "a relative",
      sibling: "a sibling",
      spouse: "a spouse"
    },
    relationshipInverse: {
      child: "Parent of {name}",
      child_of_friend_or_relative: "Friend or relative of parent",
      domestic_partner: "Domestic partner",
      foster_child: "Foster parent",
      friend: "Friend",
      grandchild: "Grandparent of {name}",
      grandparent: "Grandchild of {name}",
      myself: "Caring for self",
      niece_or_nephew: "Aunt or uncle",
      other: "Other",
      parent: "Child of {name}",
      parent_in_law: "Child-in-law of {name}",
      relative: "Relative",
      sibling: "Sibling",
      spouse: "Spouse"
    },
    relationshipInverseWithoutName: {
      child: "Parent of LO",
      child_of_friend_or_relative: "Friend or relative of parent",
      domestic_partner: "Domestic partner",
      foster_child: "Foster parent",
      friend: "Friend",
      grandchild: "Grandparent of LO",
      grandparent: "Grandchild of LO",
      myself: "Caring for self",
      niece_or_nephew: "Aunt or uncle",
      other: "Other",
      otherWithValue: 'Relationship "{value}"',
      parent: "Child of LO",
      parent_in_law: "Child-in-law of LO",
      relative: "Relative",
      sibling: "Sibling",
      spouse: "Spouse"
    },
    relationshipWithoutName: {
      child: "Child",
      child_of_friend_or_relative: "Child of my friend or relative",
      domestic_partner: "Domestic partner",
      foster_child: "Foster child",
      friend: "Friend",
      grandchild: "Grandchild",
      grandparent: "Grandparent",
      myself: "Myself",
      niece_or_nephew: "Niece or nephew",
      other: "Other",
      parent: "Parent",
      parent_in_law: "Parent-in-law",
      relative: "Relative",
      sibling: "Sibling",
      spouse: "Spouse"
    }
  },
  consentForm: {
    documentScanning: "Scanning document",
    fax: {
      error: "Error: ",
      errorCodes: {
        ALLOCATED_NUMBER_NOT_FOUND: "Number disconnected or not found",
        BUSY: "Number busy, please try again later",
        DISCONNECTED_LINE: "Number is disconnected or unreachable",
        LINE_BLACKLISTED: "Number has been blacklisted",
        NO_ANSWER: "No answer to this number",
        UNKNOWN: "An unkown error occurred"
      },
      fax: "Send new fax",
      newFax: "Fax consent form",
      sendAnotherFax: "Send a new fax",
      status: "Status: ",
      statusCodes: {
        ERROR: "Failed to convert or send fully",
        HOLD: "An error occured, reach out to the dev team",
        PENDING: "Pending, please wait",
        SUCCESS: "Fax recieved!",
        TRANSMITTING: "Sending, please wait"
      },
      to: "Fax number: ",
      viewFaxes: "Sent faxes"
    },
    reallyRevoke:
      "I revoke my authorization for the use and/or disclosure of personally identifiable information. I understand that this revocation only applies to future actions and doesn’t cancel disclosures or actions taken while this authorization was in effect.",
    revoke: "Revoke",
    revokeConsent: "Revoke consent",
    revokeHeader: "Revoke consent?",
    status: {
      COMPLETE: {
        action: "Revoke consent",
        description: "Active",
        headline: "Consent form for {name}"
      },
      EMAIL_INITIALIZED: {
        description: "Sending email to {recipientName} ({recipientEmailAddress})",
        headline: "Consent form request for {name}"
      },
      EMAIL_SENT: {
        description: "Sent email to {recipientName} ({recipientEmailAddress})",
        headline: "Consent form request for {name}"
      },
      EMBED_CREATED: {
        headline: "Consent form request for {name}"
      },
      FAILED: {
        description: "Signature failed",
        headline: "Request failed for {name}"
      },
      NEW: {
        description: "Signature starting",
        headline: "Creating a signature request for {name}"
      },
      REVOKED: {
        description: "Revoked",
        headline: "Consent form for {name}"
      }
    }
  },
  consult: {
    cancelConfirmationHeader: "Really cancel?",
    cancelConsult: "Cancel",
    cancelationReasonLabel: "Cancelation reason",
    completeConsultReport: "Complete session report",
    consultFor: "This session is for:",
    consultReportPublished: "Session report published",
    createNewJourney: "Create new journey",
    dateAndTime: "Date and time",
    editConsult: "Edit",
    editTopics: "Edit topics",
    finalized: "Finalized",
    format: {
      phone: "Phone",
      zoom: "Zoom"
    },
    genericName: "Grayce session",
    grayceExpertLabel: "Grayce Care Partner",
    initialConsultReportPublished: "Initial assessment published",
    initialGrayceConsult: "Initial Grayce session",
    inviteesLabel: "{count, plural, one {Invitee} other {Invitees}}:",
    join: "Join",
    joinByPhone: "Join by phone",
    languageSupport: "Language interpretation: {language}",
    meetingId: "Meeting ID: {id}",
    moveToAnotherJourney: "Move to another journey",
    newJourney: "Need to move a session to a new journey?",
    no_show: "Member did not show up.",
    notFinalized: "Not finalized",
    notStarted: "Not started",
    notesLabel: "Notes",
    openConsultReport: "Open session report",
    pendingInviteesLabel: "Pending {count, plural, one {invitee} other {invitees}}:",
    reallyCancel: "Are you sure you want to cancel this session?",
    reassignConsult: "Reassign session",
    reschedule: "Reschedule",
    standard: "Session was canceled, just wasn't updated in the system.",
    summaryOfConsult: "Summary of session",
    topics: {
      options: {
        ARRANGE_AUTISM_EVALUATION: "Get an evaluation for autism",
        ARRANGE_HOME_SAFETY_EVALUATION: "Arrange a home safety evaluation",
        ARRANGE_MEDICAL_SECOND_OPINION: "Arrange a medical second opinion",
        ARRANGE_MEMORY_LOSS_EVALUATION: "Arrange an evaluation for memory loss",
        ARRANGE_RIDE: "Set up one-time or recurring transportation",
        ASSISTED_LIVING_SUPPORT: "Facilitate a transition to assisted living",
        COMPARE_CARE_COSTS: "Compare the cost of care",
        COMPLETE_APPLICATIONS: "Complete applications",
        COORDINATE_EMERGENCY_RESPONSE: "Coordinate a personal emergency response system",
        FIND_DOCTOR: "Refer to local doctors or physical therapists",
        FIND_FINANCIAL_PLANNER: "Curate a list of local financial planners",
        FIND_HOME_CLEANING_SERVICES: "Curate a list of cleaning services",
        FIND_IN_HOME_AIDE: "Source a trusted in-home aide",
        FIND_LAWYER: "Refer to legal or immigration assistance",
        FIND_RESPITE_OPTIONS: "Curate a list of respite options",
        FIND_SUPPORT_GROUPS: "Source support groups",
        HANDLE_REFERRALS: "Guide long term care planning",
        HOUSING_OPTIONS: "Curate a list of housing options",
        INTERVIEW_POTENTIAL_CAREGIVERS: "Interview potential caregivers",
        MEDIATE_FAMILY_MEETING: "Mediate a family meeting",
        MENTAL_HEALTH_RESOURCES: "Refer to affordable mental health support",
        RELOCATION_GUIDANCE: "Provide guidance for relocation (locally or internationally)",
        SCHEDULE_APPOINTMENT: "Schedule medical or legal appointments",
        SET_UP_MEAL_DELIVERY: "Set up meal deliveries",
        SUBMIT_INSURANCE_CLAIMS: "Submit insurance claims"
      }
    },
    topicsLabel: "Discussion topics",
    viewConsult: "Consult information",
    whyCancel: "Why was the session canceled?"
  },
  consultReport: {
    consultReportPublished: "Session report published {date}",
    consultReportRemoved:
      "The form for the session report has been removed. Once you have filled in any profile changes below, click the finalize button below to indicate that the session has been completed. This will have the same effect that publishing the session report used to.",
    finalizeConsultReport: "Finalize session",
    finalizeInitialConsultReport: "Finalize initial session",
    forConsultOn: "For session on {startTime}",
    info: {
      clientAttendees: "Member attendee(s): ",
      duration: "Duration: {duration} minutes",
      expert: "Grayce Care Partner: {name}",
      stage: "Care journey stage: {stage}",
      title: "Session with Grayce Care Partner"
    },
    initialAssessmentPublished: "Initial assessment published {date}",
    initialConsultReportRemoved:
      "The form for the initial session report has been removed, and this data should now be filled in via the care profile. Once you have filled in the profile data below, click the finalize button below to indicate that the initial session has been completed. This will have the same effect that publishing the initial session report used to.",
    internalNotesHeader: "Internal notes",
    lastUpdated: "Last updated {date} @ {time}",
    logOutstandingConsult: "A report is needed for the session on {timestamp}.",
    nextConsultHeader: "Next session",
    noConsultsOutstanding: "There are no sessions without reports.",
    noNextConsult: "No next session provided",
    noSummary: "No summary",
    publishReport: "Publish report",
    reportOptions: {
      complete_initial_assessment: "Complete initial session report",
      consult_report: "Session report",
      initial_assessment: "Initial assessment",
      initial_assessment_finalized: "Initial session report complete"
    },
    summaryHeader: "Summary of current situation",
    unpublishableReason: "The report must be complete and have at least one discussion item in order to be published"
  },
  contentCondition: {
    kind: {
      always: "Always",
      care_profile_field_value: "Care profile",
      concern_selected: "Concern selected",
      dpoa_finances_incomplete: "Durable Power of Attorney for Finances incomplete",
      dpoa_health_care_incomplete: "Durable Power of Attorney for Health Care incomplete",
      living_will_incomplete: "Living Will incomplete",
      more_than_three_medications: "More than three medications",
      no_consult_scheduled: "None scheduled",
      no_primary_care_physician: "No primary care physician",
      provider_emotional_state_selected:
        "Today, how are you feeling emotionally about helping to care for your loved one?",
      provider_feels_supported_selected:
        "Do you feel supported (by family, friends, or others) in helping your loved one?",
      relationship_selected: "Relationship selected",
      reside_in_different_countries: "LO and Caregiver reside in different countries",
      stage_selected: "Stage selected",
      trust_incomplete: "Trust incomplete",
      types_of_support_needed_selected: "Types of support needed",
      will_incomplete: "Will incomplete"
    },
    source: {
      always: "Always",
      care_profile: "Care profile",
      care_profile_field_value: "Care profile",
      concern_selected: "Care profile",
      consults: "Sessions",
      dpoa_finances_incomplete: "Legal documents",
      dpoa_health_care_incomplete: "Legal documents",
      initialConsultReport: "Care profile",
      initial_consult_report: "Care profile",
      journey: "Journey",
      legal_documents: "Legal documents",
      living_will_incomplete: "Legal documents",
      medication_tracker: "Medication tracker",
      more_than_three_medications: "Medication tracker",
      no_consult_scheduled: "Sessions",
      no_primary_care_physician: "Provider list",
      provider_emotional_state_selected: "Care profile",
      provider_feels_supported_selected: "Care profile",
      provider_list: "Provider list",
      relationship_selected: "Care profile",
      reside_in_different_countries: "LO and caregiver reside in different countries",
      stage_selected: "Journey",
      trust_incomplete: "Legal documents",
      types_of_support_needed_selected: "Care profile",
      will_incomplete: "Legal documents"
    }
  },
  contentConditionSource: {
    always: "Always",
    care_profile: "Care profile",
    consults: "Sessions",
    medication_tracker: "Medication tracker",
    provider_list: "Provider list"
  },
  diagnosis: {
    condition: "Condition",
    control: "Control",
    controlOptions: {
      CONTROLLED: "Controlled",
      NA: "N/A",
      UNCONTROLLED: "Uncontrolled",
      UNKNOWN: "Unknown"
    },
    date: "Date of diagnosis",
    severity: "Severity",
    severityOptions: {
      MILD: "Mild",
      MODERATE: "Moderate",
      NA: "N/A",
      SEVERE: "Severe",
      UNKNOWN: "Unknown"
    }
  },
  document: {
    sentByOn: "Sent by {sentBy} on {sentOn}"
  },
  duration: {
    fifteen_minutes: "15 minutes",
    one_hour: "60 minutes",
    thirty_minutes: "30 minutes"
  },
  eligibilityFile: {
    create: "New eligibility file",
    deletingTooMuch:
      "Applying this file will delete {deleted} of {existing} existing employees. Please make sure this is correct before applying the file.",
    downloadSelectedNewHires: "Download new hires",
    executable: {
      deleted: "{count, plural, one {# employee} other {# employees}} will be deleted",
      errors: "There were {count, plural, one {# employee with an error} other {# employees with errors}}",
      executingThisWill: "Applying this eligibility file will cause the following changes:",
      incomplete:
        "{count, plural, one {# employee that was} other {# employees that were}} missing a name or email will not be applied",
      new: "{count, plural, one {# new employee} other {# new employees}} will be added",
      total: "The employer will have {count, plural, one {# employee} other {# total employees}}",
      updated: "{count, plural, one {# employee} other {# employees}} will be updated"
    },
    executeFile: "Apply this file",
    executing: "Applying eligibility file",
    getNewHires: "Select file(s) for new hires",
    hasErrors:
      "This eligibility file contains {count, plural, one {# error} other {# errors}} and cannot be applied. Please ask the tech team for support to address the issues.",
    scanning: "Checking eligibility file",
    upload: "Upload eligibility file"
  },
  employer: {
    addBenefit: "Add a benefit",
    addContract: "Add a contract",
    addCountry: "Add a country",
    addEmailDomain: "Add an email domain",
    attributes: {
      additionalPackageDetails: "Additional package details",
      address: "Address",
      billableCalculation: "Billable calculation method",
      billableCap: "Billable member cap",
      contractBeginDate: "Contract begin date",
      contractEndDate: "Contract end date",
      culture: "Other context on employer culture",
      eligibilityMethod: "Eligibility method",
      emailDomains: "Email domains",
      employeeCount: "Employee count",
      employeeCountByCountries: "Employee count by country",
      employeesPurchased: "Employees purchased",
      enrollmentCode: "Enrollment code",
      expectedUtilization: "Expected utilization",
      fileShareFrequency: "File share frequency",
      fileShareMethod: "File share method",
      fileSubmissionTimeFrame: "File submission time frame",
      globalPopulation: "Global population",
      hasCarePlanning: "Has care planning package",
      hideBenefits: "Marketplace benefits hidden",
      industry: "Industry",
      minutes: "Minutes",
      name: "Name",
      packageCost: "Package cost",
      paymentTerms: "Payment terms",
      platformFee: "Platform fee",
      signupLink: "Signup link",
      website: "Website"
    },
    create: "Create employer",
    editBenefit: "Edit benefit",
    editContract: "Edit contract",
    editContractModification: "Edit contract modification",
    eligibilityMethod: {
      ELIGIBILITY_FILE: "Eligibility file",
      ENROLLMENT_CODE: "Enrollment code",
      SSO: "SSO",
      WORK_EMAIL_DOMAIN: "Work email verification"
    },
    employeeCount: ", Employee count: {count}",
    fileShareFrequency: {
      MONTHLY: "Monthly",
      WEEKLY: "Weekly"
    },
    fileShareMethod: {
      DROPBOX: "Dropbox",
      SFTP: "SFTP"
    },
    industry: {
      accounting: "Accounting",
      aviation: "Aviation",
      benefit_providers: "Benefit providers",
      construction: "Construction",
      fashion: "Fashion",
      government_services: "Government services",
      legal: "Legal",
      other: "Other",
      technology: "Technology"
    },
    newContractModification: "New contract modification",
    sections: {
      benefits: "Benefits",
      contractInfo: "Current contract info",
      contractInfoExplanation:
        "This represents the current settings of the contract with all active modifications applied",
      contracts: "Contracts",
      employerInfo: "Employer info",
      history: "History"
    }
  },
  employerBenefit: {
    addLink: "Add a link",
    title: "Explore: {title}"
  },
  employerBenefitCategory: {
    ALL: "All",
    CHILD_CARE: "Child care",
    FAMILY_PLANNING: "Family planning",
    FINANCIAL: "Financial",
    FITNESS: "Fitness",
    LEGAL: "Legal",
    MENTAL_HEALTH: "Mental health",
    OTHER: "Other",
    WELLNESS: "Wellness"
  },
  employerContract: {
    attributes: {
      billableCalculation: "Billable calculation method",
      billableCap: "Billable member cap",
      contractBeginDate: "Contract begin date",
      contractEndDate: "Contract end date",
      employeesPurchased: "Employees purchased",
      hasCarePlanning: "Has care planning package",
      minutes: "Minutes",
      packageCost: "Package cost",
      paymentTerms: "Payment terms",
      platformFee: "Platform fee"
    },
    billableCalculation: {
      ON_SIGNUP: "Billable upon signup",
      ON_USAGE: "Billable upon usage"
    },
    createContract: "Create contract",
    editRefreshPeriod: "Edit refresh period",
    employerContractInfo: "Contract info",
    employerContractInfoWithoutModificationsExplanation:
      "This represents the values in the contract without modifications applied. See below for the modifications that have been added.",
    sections: {
      modifications: "Contract modifications",
      refreshPeriods: "Refresh periods"
    }
  },
  employerContractModification: {
    reallyDelete:
      "Are you sure you want to delete this contract modification? It has not yet been applied, so deleting it will have no effect on existing members."
  },
  expert: {
    attributes: {
      bio: "Bio",
      email: "Email",
      fullName: "Name",
      memberCount: "Member count",
      photo: "Photo",
      specialties: "Specialties",
      timezone: "Timezone",
      zoomId: "Zoom id"
    },
    consultReportStatus: {
      all: "All",
      complete: "Complete",
      incomplete: "Incomplete"
    },
    consultTimePeriod: {
      all: "All",
      past: "Past",
      upcoming: "Upcoming"
    },
    coversUsEast: "US East",
    coversUsWest: "US West",
    managerOverviewTimePeriod: {
      all_time: "All time",
      custom: "Custom",
      ninety_days: "Last 90 days",
      seven_days: "Last 7 days",
      sixty_days: "Last 60 days",
      thirty_days: "Last 30 days"
    },
    needsInterpretation: {
      all: "All",
      needs_interpretation: "Needs language interpretation"
    }
  },
  explore: {
    articleType: {
      my_story: "My story",
      text: "Articles",
      video: "Videos",
      webinar: "Live events"
    },
    eventType: {
      PAST: "Past",
      UPCOMING: "Upcoming"
    },
    tab: {
      EVENTS: "Events",
      MEDIA: "Media",
      PINS: "Pins"
    }
  },
  focusArea: {
    descriptions: {
      financial: "Understand your financial options and how to make the most of benefits",
      functional: "Set up safe and happy everyday living arrangements",
      legal: "Document important decisions and prepare for the future",
      medical: "Manage medical conditions, medications, appointments, and more with your loved ones",
      social: "Support your loved ones' emotional, social, and spiritual wellness",
      under_eighteen: "Provide assistance throughout the many stages of childhood development",
      wellbeing: "Take care of yourself while helping others"
    },
    financial: "Financial",
    functional: "Care & home",
    legal: "Legal",
    medical: "Medical",
    social: "Social",
    under_eighteen: "Under 18",
    wellbeing: "Your well-being",
    wellbeingWithoutYour: "Well-being"
  },
  folderKind: {
    CONSENT_FORMS: "Consent forms",
    DOCUMENTS: "Documents",
    WORKSHEETS: "Worksheets"
  },
  goal: {
    add: "Add to Care Plan",
    media: {
      add: "Add media"
    },
    sections: {
      all: "Full goal",
      carePartnerTasks: "Care Partner tasks",
      media: "Media",
      memberTasks: "Member tasks",
      tools: "Tools"
    },
    tools: {
      add: "Add tools"
    }
  },
  goalItemTypes: {
    add: {
      ARTICLE: "Browse article library",
      CARE_PLAN_ACTION_ITEM: "Browse member task library",
      EMPLOYER_BENEFIT: "Browse marketplace library",
      GOAL: "Browse goal library",
      TASK: "Browse Care Partner task library",
      TOOLS_AND_WORKSHEETS: "Browse tool library",
      WORKSHEET: "Browse tool library"
    },
    title: {
      ARTICLE: "Articles",
      CARE_PLAN_ACTION_ITEM: "Member tasks",
      EMPLOYER_BENEFIT: "Marketplace",
      GOAL: "Goals",
      TASK: "Expert tasks",
      TOOLS_AND_WORKSHEETS: "Tools",
      WORKSHEET: "Tools"
    }
  },
  goalTemplate: {
    create: "Set goal",
    kind: {
      ALL: "All",
      GLOBAL: "Global",
      JOURNEY_SPECIFIC: "Journey specific"
    },
    tableHeadings: {
      CREATED_AT: "Date added",
      TITLE: "Title",
      UPDATED_AT: "Last updated",
      actions: "Actions",
      author: "Author",
      focusAreas: "Focus area(s)",
      journey: "Journey",
      tags: "Tags"
    },
    toolKind: {
      forms: "Forms",
      quizzes: "Quizzes",
      tracker: "Trackers",
      worksheets: "Interactive worksheets"
    },
    tools: {
      complete_consent_form: "Consent form",
      complete_legal_document_tracker: "Legal document tracker",
      complete_medication_tracker: "Medication tracker",
      complete_provider_list: "Provider list"
    }
  },
  hour: {
    hourOptions: {
      0: "12 AM",
      1: "1 AM",
      10: "10 AM",
      11: "11 AM",
      12: "12 PM",
      13: "1 PM",
      14: "2 PM",
      15: "3 PM",
      16: "4 PM",
      17: "5 PM",
      18: "6 PM",
      19: "7 PM",
      2: "2 AM",
      20: "8 PM",
      21: "9 PM",
      22: "10 PM",
      23: "11 PM",
      3: "3 AM",
      4: "4 AM",
      5: "5 AM",
      6: "6 AM",
      7: "7 AM",
      8: "8 AM",
      9: "9 AM"
    }
  },
  initialConsultReport: {
    genericName: "Initial session report",
    questions: {
      care_profile_country_code: {
        content: "What country does the loved one live in?"
      },
      care_profile_language_code: {
        content: "What language will the loved one need support with?"
      },
      client_country_code: {
        content: "Member country"
      },
      client_cultural_considerations: {
        content: "What language or cultural considerations should I know about you?"
      },
      client_is_veteran: {
        content: "Are you a veteran?"
      },
      client_language_code: {
        content: "What language will the member need support with?"
      },
      client_location: {
        content: "Member full address, city, or ZIP code"
      },
      client_name: {
        content: "Name of member"
      },
      client_role_relationship: {
        content: "What is {name}'s relationship to you?"
      },
      client_timezone: {
        content: "Member timezone"
      },
      cognitive_statuses: {
        content: "Do you have any concerns about your loved one's cognition?"
      },
      conclusion: {
        benefitsExplained: "Did you explain Grayce benefits",
        clientFollowups: "Member follow-up items",
        expertFollowups: "GE follow-up items",
        nextConsult: "Next session is recommended"
      },
      cultural_considerations: {
        content: "Does your loved one have any cultural or language considerations that I should know of?"
      },
      emergency_plan_items: {
        content: "How would your loved one get help in an emergency? (select all that apply)"
      },
      emotional_concerns: {
        content: "Do you have any concerns about your loved one's emotional well-being?"
      },
      employer: {
        content: "Member employer"
      },
      fall_count: {
        content: "How many times has your loved one fallen in the last twelve months?"
      },
      financial_manager: {
        content: "Who manages your loved one's finances and pays their bills?"
      },
      health_insurance_types: {
        content: "What type of health insurance does your loved one have?"
      },
      insurance_types: {
        content: "Does your loved one have any other types of insurance?"
      },
      is_veteran_guardian: {
        content: "Veteran status of parent or legal guardian"
      },
      is_veteran_partner: {
        content: "Veteran status of partner"
      },
      is_veteran_self: {
        content: "Member veteran status"
      },
      legal_documents: {
        content: "Legal documents",
        legal_dpoa_finances_status: {
          content: "DPOA - Finances"
        },
        legal_dpoa_health_care_status: {
          content: "DPOA - Health care"
        },
        legal_living_will_status: {
          content: "Living will"
        },
        legal_trust_status: {
          content: "Trust"
        },
        legal_will_status: {
          content: "Will"
        }
      },
      location: {
        content: "Where do they live? (city, state, country)"
      },
      marital_status: {
        content: "What is the loved one's marital status?"
      },
      needs: {
        basicNeeds: "Basic needs",
        care: "Care",
        content: "Needs",
        emergencyPlanning: "Emergency planning",
        financial: "Financial",
        financialPlanning: "Financial planning",
        financialPrograms: "Financial programs",
        food: "Food",
        functional: "Functional",
        insurance: "Insurance",
        legal: "Legal",
        medical: "Medical",
        medicalResources: "Medical resources",
        medications: "Medications",
        mentalHealth: "Mental health",
        met: "Met",
        not_met: "Not met",
        resources: "Resources",
        social: "Social",
        transportation: "Transportation",
        yourWellbeing: "Wellbeing"
      },
      recent_hospitalization: {
        content: "Has your loved one been hospitalized or gone to the emergency room in the last six months?",
        options: {
          no: {
            content: "No"
          },
          yes: {
            content: "Yes"
          }
        },
        synopsis: "Short synopsis"
      },
      religion: {
        content: "Does your loved one have a religious affiliation?"
      },
      residence_type: {
        content: "Where does your loved one live?"
      },
      stability_supports: {
        content: "Does your loved one hold anything for stability while walking?"
      },
      stages: {
        content: "Activities of daily living",
        types: {
          bathing_and_dressing: "Bathing/dressing",
          continence: "Continence",
          eating: "Eating",
          housekeeping: "Housekeeping",
          meal_prep: "Meal prep",
          shopping: "Shopping",
          toileting: "Toileting",
          transportation: "Transportation"
        }
      },
      stairs_required_for_exit: {
        content: "Can your loved one exit the home without using stairs?"
      }
    }
  },
  internalNote: {
    editInternalNote: "Edit internal note",
    hideVersionHistory: "Hide version history",
    kinds: {
      NOTE: "Note",
      ROADMAP: "Plan",
      SYNOPSIS: "Synopsis"
    },
    onlyVisibleToExperts: "Only visible to Grayce Care Partners",
    reallyDelete: "Are you sure you want to delete this internal note?",
    showVersionHistory: "Show version history"
  },
  internalNoteTemplate: {
    create: "Create internal note template"
  },
  journey: {
    category: {
      all: "All",
      billable: "Billable",
      no_expert: "No Care Partner",
      primary: "Primary",
      unverified: "Unverified emails"
    },
    expertArchivedReasons: {
      client_disinterest: "Member does not want to use Grayce",
      death_of_loved_one: "Member opted out due to death of loved one",
      other: "Other",
      situation_resolved: "Situation resolved"
    },
    expertRoles: {
      all: "All",
      backup: "Backup",
      primary: "Owner",
      team_member: "Supporting"
    },
    nameWithOwner: "{owner} - {lovedOne}",
    stage: {
      bereavement: "Bereavement",
      crisis: "Crisis",
      dependent: "Dependent",
      end_of_life: "End of life",
      independent: "Independent",
      interdependent: "Interdependent",
      none: "No stage selected"
    },
    status: {
      active: "Active",
      all: "All",
      awaiting_initial_consult: "Awaiting initial session",
      awaiting_initial_consult_reschedule: "Awaiting initial session reschedule",
      care_plan_in_progress: "Care Plan in progress",
      care_plan_published: "Care Plan published",
      engaged: "Engaged",
      follow_up_consult_scheduled: "Follow up session scheduled",
      inactive: "Inactive",
      pre_consult: "Pre-session",
      signed_up: "Signed up"
    },
    tagListLabel: "Tags"
  },
  journeysSearch: {
    after: "after",
    before: "before",
    dataType: {
      CARE_PLAN_ACTION_ITEMS: "Member task",
      FILE_RESOURCES: "Drive",
      GOALS: "Goal",
      INTERNAL_NOTES: "Internal note",
      JOURNEYS: "All",
      MESSAGES: "Message",
      PROFILE_DATA: "Profile data",
      RESOURCES: "Resource",
      TASKS: "Care Partner task"
    },
    dateRangeTypes: {
      CREATED_AT: "Journey created",
      LAST_ACTIVITY_AT: "Journey last edited"
    },
    endDate: "End date",
    noResults: "No results found",
    sortBy: {
      CREATED_AT_ASC: "Created: oldest first",
      CREATED_AT_DESC: "Created: newest first",
      LAST_ACTIVITY_AT_ASC: "Updated: oldest first",
      LAST_ACTIVITY_AT_DESC: "Updated: newest first",
      SCORE: "Best match"
    },
    startDate: "Start date"
  },
  language: {
    type: {
      both: "Both",
      client: "Yourself",
      loved_one: "Your loved one"
    }
  },
  learn: {
    allTitles: {
      featuredStories: "Featured stories",
      financial: "All financial",
      functional: "All care & home",
      legal: "All legal",
      liveEventLibrary: "Live event library",
      medical: "All medical",
      myStories: "All featured stories",
      pastWebinars: "All past live events",
      saved: "All saved ({count})",
      savedWithoutCount: "All saved",
      social: "All social connection",
      under_eighteen: "All under 18",
      upcomingLiveEvents: "Upcoming live events",
      wellbeing: "All your well-being"
    },
    contentType: {
      label: "Content type:"
    },
    contentTypeOptions: {
      all: "All",
      my_story: "Featured stories",
      text: "Articles",
      video: "Videos",
      webinar: "Live events"
    },
    focusArea: {
      label: "Focus area:"
    },
    options: {
      all: "All",
      financial: "Financial",
      functional: "Care & home",
      legal: "Legal",
      medical: "Medical",
      saved: "Saved",
      social: "Social connection",
      under_eighteen: "Under 18",
      wellbeing: "Your well-being"
    },
    titles: {
      careAndHome: "Care & home",
      financial: "Financial",
      functional: "Care & home",
      legal: "Legal",
      medical: "Medical",
      personalizedForYou: "Guides picked just for you",
      saved: "Saved ({count})",
      social: "Social connection",
      socialConnection: "Social connection",
      under_eighteen: "Under 18",
      wellbeing: "Your well-being",
      yourWellbeing: "Your well-being"
    }
  },
  legalDocument: {
    kindOptions: {
      dpoa_finances: "Durable Power of Attorney for Finances",
      dpoa_health_care: "Durable Power of Attorney for Health Care",
      guardian_trust: "Parent/guardian trust",
      guardian_will: "Parent/guardian will",
      living_will: "Living Will",
      trust: "Trust",
      will: "Will"
    },
    statusOptions: {
      complete: "Complete",
      incomplete: "Not complete",
      not_sure: "I'm not sure"
    }
  },
  medication: {
    formatOptions: {
      injection: "Injection",
      oral: "Oral",
      other: "Other",
      suppository: "Suppository",
      tablet: "Tablet",
      topical: "Topical"
    },
    kindOptions: {
      over_the_counter: "Over-the-counter medication",
      prescription: "Prescription medication",
      vitamin_or_supplement: "Vitamins & supplements"
    },
    paymentTypeOptions: {
      covered_by_insurance: "Covered by insurance",
      self_pay: "Not covered. Self pay.",
      unknown: "Unknown"
    }
  },
  message: {
    attachments: "Attachments",
    body: "Body",
    clientSender: "Member",
    createdAt: "Sent at",
    expertSender: "Care Partner",
    from: "From",
    sender: "Sender",
    to: "To"
  },
  messages: {
    areInMessage: "are in this message",
    areTyping: " {count, plural, one {is} other {are}} typing",
    careGroupMessaging: "Care group messaging",
    chatSpaceDescription: "Easily message your Care Partner and care team below.",
    closeMessages: "Close messages",
    dontAskAgain: "Don't ask me again",
    groupMembers: "You’re about to send a group message to {members}. Do you want to continue?",
    inThisMessage: "In this message:",
    markAsRead: "Mark as read",
    markAsUnread: "Mark as unread",
    messageMember: "Message {firstName}",
    messageYourCareTeam: "Message your care group",
    messageYourExpert: "Message your Care Partner",
    messagesLabel: "Messages",
    newMessage: "New message",
    noResponseMessage: "INTERNAL ONLY: No response needed",
    noResponseNeeded: "No response needed",
    sendGroupMessage: "Send group message?",
    sendMessage: "Send message",
    sensitive: "At Grayce, we understand when caring for others, sensitive information may need to be shared.",
    tableHeadings: {
      attachments: "Attachments",
      from: "From",
      journey: "Journey",
      preview: "Preview",
      sentAt: "Sent at",
      to: "To"
    },
    yourCareGroup: "Your care group",
    yourExpert: "Your Care Partner",
    yourExperts: "Your Care Partners"
  },
  npsSurvey: {
    headline: "How likely are you to recommend Grayce to a friend?",
    label: "Your input is valuable to us"
  },
  packageGrant: {
    approved: "Approved",
    eligibilityStatus: {
      AWAITING_WORK_EMAIL_VERIFICATION: "Awaiting work email verification",
      ELIGIBLE: "Eligible",
      INELIGIBLE: "Ineligible",
      NEEDS_REVIEW: "Needs admin review",
      PENDING: "Pending",
      UNDETERMINED: "Undetermined"
    },
    pending: "Pending",
    rejected: "Rejected",
    tableHeadings: {
      details: "Details",
      expirationDate: "Expiration date",
      purchaseDate: "Purchase date",
      purchasedBy: "Purchased by",
      timeRemaining: "Remaining balance"
    }
  },
  pdfType: {
    medication_tracker: "Your medication list",
    provider_tracker: "Your provider list"
  },
  provider: {
    kindOptions: {
      other_care_provider: "Other care provider",
      other_doctor: "Other doctor",
      primary_care_provider: "Primary care provider"
    },
    specialtyOptions: {
      allergist: "Allergist",
      audiologist: "Audiologist / hearing aid vendor",
      cardiologist: "Cardiologist",
      dentist: "Dentist",
      dermatologist: "Dermatologist",
      dmeVendor: "Durable Medical Equipment (DME) vendor",
      endocrinologist: "Endocrinologist",
      gastroenterologist: "Gastroenterologist",
      geriatrician: "Geriatrician",
      gynecologist: "Gynecologist",
      hematologist: "Hematologist",
      infectiousDiseaseSpecialist: "Infectious disease specialist",
      nephrologist: "Nephrologist",
      neurologist: "Neurologist",
      occupationalTherapist: "Occupational therapist",
      oncologist: "Oncologist",
      ophthalmologist: "Ophthalmologist",
      optometrist: "Optometrist / glasses vendor",
      other: "Other",
      palliativeCareSpecialist: "Palliative care specialist",
      physicalTherapist: "Physical therapist",
      podiatrist: "Podiatrist",
      psychiatrist: "Psychiatrist, psychologist, or counselor",
      pulmonologist: "Pulmonologist",
      speechTherapist: "Speech therapist",
      urologist: "Urologist"
    }
  },
  pushNotificationActions: {
    OPEN_CARE_PLAN: "Open the Care Plan",
    OPEN_CONSULTS: "Open the sessions page",
    OPEN_MESSAGES: "Open messages"
  },
  ratings: {
    extremelyLikely: "Extremely likely",
    notLikely: "Not likely at all"
  },
  refreshPeriod: {
    addCredit: "Add credit",
    billableCountsDisplay: "({new} new, {refresh} refresh)",
    credits: "Credits"
  },
  refreshPeriodCredit: {
    create: "Add credit"
  },
  resource: {
    addResource: "Add resource",
    editResource: "Edit resource"
  },
  resourceFeatureTemplate: {
    create: "Create resource feature template",
    tableHeadings: {
      actions: "Actions",
      createdAt: "Created at",
      featureCount: "Feature count",
      title: "Title"
    }
  },
  restrictedAccess: {
    hoursUsed:
      "You have used up the hours that your employer has purchased for you. Add hours to your account to continue using Grayce’s benefits and features.",
    purchaseHours: "Purchase hours",
    purchaseMore: "Purchase more hours"
  },
  session: {
    idlePrompt:
      "You've been inactive in Grayce for a little while. You'll be automatically logged out unless you use the app within 1 minute"
  },
  signedDocument: {
    closeBrowserWindow: "Close this window to return to Grayce.",
    kindOptions: {
      CONSENT_FORM: "Consent form"
    }
  },
  specialties: {
    age: {
      deceased: "They're deceased",
      eighteen_to_sixty_four: "18-64",
      sixty_five_and_older: "65 years and older",
      under_eighteen: "Under 18"
    },
    condition: {
      adhd: "ADHD/ADD",
      allergies: "Allergies",
      alzheimer: "Alzheimer’s disease",
      asthma: "Asthma",
      autism: "Autism",
      cancer: "Cancer",
      cerebral_palsy: "Cerebral palsy",
      chd: "CHD",
      dementia: "Dementia",
      diabetes: "Diabetes",
      down_syndrome: "Down dyndrome",
      dyslexia: "Dyslexia",
      heart_disease: "Heart disease",
      infertility: "Infertility",
      juvenile_diabetes: "Juvenile diabetes",
      kidney_conditions: "Kidney conditions",
      lung_conditions: "Lung conditions",
      skin_afflictions: "Skin afflictions (eczema, and psoriasis)",
      spina_bifida: "Spina bifida",
      stroke: "Stroke"
    },
    other: {
      adoption: "Adoption",
      advanced_directives: "Advanced directives",
      advantage_plans: "Advantage plans",
      application_process: "Application process",
      assisted_living: "Assisted living",
      backup_care: "Backup care",
      behavior_concerns: "Behavior concerns",
      care_management: "Care management",
      caregiver_wellbeing: "Caregiver well-being",
      caregiving_stress: "Caregiving stress",
      college_prep: "College prep",
      communication_struggles: "Communication struggles",
      coparenting: "Coparenting",
      counselors: "Counselors",
      daycare: "Daycare",
      developmental_delay: "Developmental delay",
      difficult_conversations: "Difficult conversations",
      dnrs: "DNRs",
      doctors: "Doctors (locating)",
      drug_costs: "Drug costs",
      early_intervention: "Early intervention",
      education_scholarships: "Education scholarships",
      educational_technology: "Educational technology",
      estate_planning: "Estate planning",
      extracurricular_activities: "Extracurricular activities",
      fafsa: "FAFSA",
      falling_behind_covid: "Falling behind/COVID",
      family_attorneys: "Family attorneys",
      family_support: "Family support",
      financial_programs: "Financial programs",
      five_hundred_fours: "504s",
      geriatricians: "Geriatricians",
      home_health: "Home health",
      home_school: "Home school",
      hospice: "Hospice",
      iep: "IEP",
      lactation: "Lactation",
      lawyers: "Lawyers (locating)",
      learning_challenges: "Learning challenges",
      learning_disabilities: "Learning disabilities",
      legal_documents: "Legal documents",
      living_wills: "Living wills",
      loans_grants_scholarships: "Loans/grants/scholarships",
      long_term_care_insurance: "Long term care insurance",
      ltc_insurance: "LTC insurance",
      ltc_providers: "LTC providers",
      medicaid: "Medicaid",
      medical_bills: "Medical bills",
      medical_insurance: "Medical insurance",
      medicare: "Medicare",
      medicare_advantage: "Medicare advantage",
      medication_management: "Medication management",
      medigap: "Medigap",
      memory_care: "Memory care",
      mental_health: "Mental health",
      nannies_babysitters: "Nannies/babysitters",
      nursing_homes: "Nursing homes",
      nutrition: "Nutrition",
      online_learning: "Online learning",
      parkinson_disease: "Parkinson’s disease",
      pediatricians: "Pediatricians",
      power_of_attorney: "Power of attorney",
      prenatal_prep: "Prenatal prep",
      pt_ot_st: "PT/OT/ST",
      quarantine_questions: "Quarantine questions",
      rehabilitation: "Rehabilitation",
      sensory_processing: "Sensory processing",
      special_needs: "Special needs",
      special_needs_education: "Special needs education",
      specialists: "Specialists",
      speech: "Speech",
      sports_injuries: "Sports injuries",
      summer_programs: "Summer programs",
      support_groups: "Support groups (locating)",
      therapeutic_resources: "Therapeutic resources",
      transportation_resources: "Transportation resources",
      tutoring: "Tutoring",
      va_benefits: "VA benefits",
      wills: "Wills"
    }
  },
  status: {
    archived: "Archived",
    canceled: "Canceled",
    completed: "Ready",
    draft: "Draft",
    in_progress: "In progress",
    new: "New",
    publishQuestion: "Publish?",
    published: "Published",
    unpublished_items: "Unpublished items",
    updated: "Updated",
    updating: "Updating",
    viewed: "Viewed"
  },
  surveyServices: {
    delighted: "In-app survey",
    typeform: "Session survey"
  },
  tagList: {
    overflow: "+{count}"
  },
  tasks: {
    addTask: "Add Care Partner task",
    assignee: {
      all: "All",
      me: "Me",
      others: "Others"
    },
    categoryOptions: {
      ADAPTIVE_EQUIPMENT: "Adaptive equipment",
      ADVANCE_CARE_PLANNING: "Advance care planning",
      ADVOCACY: "Advocacy",
      ASSISTED_LIVING: "Assisted living",
      ATTORNEYS: "Attorneys",
      BEREAVEMENT: "Bereavement and grief",
      CAREGIVING: "Caregiving",
      CHILDCARE: "Childcare",
      CRISIS_RESPONSE: "Crisis response",
      DIAGONSIS_SPECIFIC_EDUCATION: "Diagnosis specific education and resources",
      DISPENSERS: "Automatic medication dispensers",
      DOCTORS: "Doctors",
      EDUCATION: "Education",
      ELDER_LAW_ATTORNEYS: "Elder law attorneys",
      EMERGENCY_RESPONSE_SYSTEMS: "Personal emergency response systems",
      EMPLOYER_BENEFITS: "Employer benefits",
      FINANCIAL_PLANNING: "Financial and estate planning",
      FINANCIAL_RESOURCES: "Financial resources",
      HEALTHCARE_PROVIDERS: "Healthcare providers",
      HEALTH_CARE_ANESTHESIOLOGY: "Healthcare: anesthesiology",
      HEALTH_CARE_CARDIOLOGY: "Healthcare: cardiology",
      HEALTH_CARE_DERMATOLOGY: "Healthcare: dermatology",
      HEALTH_CARE_EMERGENCY_MEDICINE: "Healthcare: emergency medicine",
      HEALTH_CARE_ENDOCRINOLOGY: "Healthcare: endocrinology",
      HEALTH_CARE_FACILITIES: "Healthcare facilities",
      HEALTH_CARE_FAMILY_MEDICINE: "Healthcare: family medicine",
      HEALTH_CARE_GASTROENTEROLOGY: "Healthcare: gastroenterology",
      HEALTH_CARE_GERONTOLOGY: "Healthcare: gerontology",
      HEALTH_CARE_HEMATOLOGY: "Healthcare: hematology",
      HEALTH_CARE_IMMUNOLOGY: "Healthcare: immunology",
      HEALTH_CARE_INTERNAL_MEDICINE: "Healthcare: internal medicine",
      HEALTH_CARE_NEPHROLOGY: "Healthcare: nephrology",
      HEALTH_CARE_NEUROLOGY: "Healthcare: neurology",
      HEALTH_CARE_OBSTETRICS_GYNECOLOGY: "Healthcare: obstetrics & gynecology",
      HEALTH_CARE_OCCUPATIONAL_THERAPY: "Healthcare: occupational therapy",
      HEALTH_CARE_ONCOLOGY: "Healthcare: oncology",
      HEALTH_CARE_OPTOMETRY: "Healthcare: optometry",
      HEALTH_CARE_OPTOMOLOGY: "Healthcare: optomology",
      HEALTH_CARE_PEDIATRICS: "Healthcare: pediatrics",
      HEALTH_CARE_PHYSICAL_THERAPY: "Healthcare: physical therapy",
      HEALTH_CARE_PLASTIC_SURGERY: "Healthcare: plastic surgery",
      HEALTH_CARE_RADIOLOGY: "Healthcare: radiology",
      HEALTH_CARE_RHEUMATOLOGY: "Healthcare: rheumatology",
      HEALTH_CARE_SPEECH_THERAPY: "Healthcare: speech therapy",
      HEALTH_CARE_SURGERY: "Healthcare: surgery",
      HEALTH_CARE_UROLOGY: "Healthcare: urology",
      HELPFUL_LINKS: "Helpful links, articles, and tools",
      HELPLINES: "Helplines",
      HOME_HEALTH_CARE_SERVICES: "Home health care",
      HOME_SAFETY: "Home safety",
      HOSPICE: "Hospice and palliative care",
      HOUSEHOLD_SERVICES: "Household services",
      HOUSE_CLEANING: "House cleaning",
      HOUSING: "Housing",
      INDEPENDENT_LIVING: "Independent living",
      INSURANCE: "Insurance",
      LEGAL_SERVICES: "Legal services",
      LEGAL_SERVICES_CRIMINAL: "Legal services: criminal",
      LEGAL_SERVICES_ESTATE_PLANNING: "Legal services: estate planning",
      LEGAL_SERVICES_FAMILY: "Legal services: family",
      LEGAL_SERVICES_IMMIGRATION: "Legal services: immigration",
      LEGAL_SERVICES_MEDICAL: "Legal services: medical",
      LEGAL_SERVICES_SENIOR: "Legal services: senior",
      LEGAL_SERVICES_SOCIAL_SECURITY: "Legal services: social security",
      LEGAL_SERVICES_TAX: "Legal services: tax",
      LONG_TERM_CARE_INSURANCE: "Long term care insurance",
      MAIL_ORDER_PHARMACY: "Mail order pharmacy",
      MEALS: "Meals",
      MEDICAID: "Medicaid",
      MEDICARE: "Medicare",
      MEDICATION_MANAGEMENT: "Medication management",
      MEMORY_CARE: "Memory care",
      MENTAL_HEALTH_THERAPISTS: "Mental health therapists",
      MOVING_OR_RELOCATION: "Moving or relocation",
      NUTRITION: "Nutrition",
      OTHER: "Other",
      PACE: "Programs of All-Inclusive Care for the Elderly (PACE)",
      PARENT_EDUCATION_SUPPORT: "Parent education & support",
      PERSONAL_CARE: "Personal care",
      PROBATE_AND_DEATH: "Probate and death",
      REHABILITATION: "Rehabilitation",
      RESIDENTIAL_CARE_HOME: "Residential care home",
      RESPITE: "Respite",
      SENIOR_CENTERS: "Senior centers",
      SENIOR_LIVING_COMMUNITIES: "Senior living communities",
      SOCIALIZATION_AND_RECREATIONAL: "Socialization and recreational",
      SOCIAL_ACTIVITIES: "Social activities",
      SOCIAL_CONNECTION_AND_ACTIVITIES: "Social connection and activities",
      SOCIAL_SECURITY: "Social security",
      SUPPORT_GROUPS: "Support groups",
      TELEHEALTH: "Telehealth",
      TRANSPORTATION: "Transportation",
      VETERANS_RESOURCES: "Veterans resources"
    },
    clientVisibleOptions: {
      no: "Member cannot view",
      yes: "Member can view"
    },
    editResourceFeatures: "Edit feature criteria",
    editTask: "Edit Care Partner task",
    goToReport: "Go to report",
    inactive: "Inactive",
    initial: "initial",
    kind: {
      care_plan_action_item_completed: "{clientName} completed a task",
      care_plan_action_item_incompleted: "{clientName} marked a task as incomplete",
      client_purchase: "{clientName} has purchased a package. {viewAccount}",
      confirm_consult_interpretation:
        "Confirm that {language} interpretation services are setup for session on {startDate} at {startTime} {languageServicesLink}",
      consult_interpretation:
        "Schedule interpretation service: {language} for session on {startDate} {languageServicesLink}",
      journey_became_active: "The journey became active",
      legal_tracker_completed: "The legal tracker was completed",
      medication_tracker_completed: "The medication tracker was completed",
      nine_day_check_in: "Post {type} session 9 day check in",
      ninety_day_check_in: "Post {type} session 90 day check in",
      pme_completed: "Personalize my experience was completed",
      prepare_for_consult: "Prepare for upcoming session",
      provide_resources: "Provide resources",
      provider_tracker_completed: "The provider tracker was completed",
      publish_care_plan: "Publish Care Plan",
      resource_marked_not_useful: "{clientName} marked a resource as not useful",
      resource_marked_useful: "{clientName} marked a resource as useful",
      review_resource: 'Get started on resource "{title}"',
      signed_document_completed: "The signed document for {name} was completed",
      signed_document_revoked: "The signed document for {name} was revoked",
      sixty_day_check_in: "Post {type} session 60 day check in",
      submit_consult_report: "Submit {reportType}. {goToReport}",
      task_canceled: "{clientName} has canceled {title}",
      task_reminder: "Update: {goToCarePlan}",
      thirty_day_check_in: "Post {type} session 30 day check in",
      worksheet_completed: '{clientName} has completed the worksheet "{title}"',
      worksheet_started: '{clientName} has started the worksheet "{title}"'
    },
    reportType: {
      consultReport: "session report",
      initialConsultReport: "initial session report"
    },
    setupInterpretation: "See how to setup interpretation services",
    status: {
      all: "All",
      completed: "Complete",
      incomplete: "Incomplete"
    },
    taskClientVisibleOptions: {
      ANY_TASK: "Any task",
      CLIENT_VISIBLE: "Only client-visible tasks",
      EXPERT_ONLY: "Only Care Partner tasks"
    },
    taskListLabel: "Task list",
    viewAccount: "View account"
  },
  timeLog: {
    actualAndScheduled: "Actual / Scheduled",
    addTimeLog: "Add time log",
    billableOptions: {
      all: "All",
      billable: "Billable",
      non_billable: "Non-billable"
    },
    editTimeLog: "Edit time log",
    reallyDelete: "Are you sure you want to delete this time log?"
  },
  timeLogActivityKinds: {
    ADDITIONAL_CONSULTS: {
      description: "For all sessions after the second session (Rounded to the nearest 5 min)",
      title: "Additional session (3+)"
    },
    ADDITIONAL_CONSULTS_DOCUMENTATION: {
      description: "Session summary, updating synopsis/roadmap/CP. Does not include additional resource time",
      title: "Additional session (3+) documentation"
    },
    ASSESSMENT_CREATION: {
      description: "Assessment creation & storing",
      title: "Assessment creation & storing"
    },
    CARE_PLAN_CREATION: {
      description: "CP creation time",
      title: "Care Plan creation"
    },
    CARE_PLAN_LITE: {
      description: "Adding resources for Care Plan lite",
      title: "Care Plan lite"
    },
    CARE_PLAN_REVIEW: {
      description: "Care Plan review discussion",
      title: "Care Plan review discussion"
    },
    CARE_PLAN_UPDATE: {
      description: "CP update time, non-session",
      title: "Care Plan update"
    },
    CARE_PLAN_UPDATING: {
      description: "Continuous Care Plan updating & summaries",
      title: "Continous Care Plan updating & summaries"
    },
    CLIENT_MESSAGING: {
      description: " ",
      title: "Member messaging"
    },
    CONCIERGE_SERVICES: {
      description: 'Different from resource vetting. Setting up appointments, etc. "the extra mile"',
      title: "Concierge services"
    },
    CONSULT_PREP: {
      description: "Pre-session prep",
      title: "Session prep"
    },
    FOLLOW_UP_CONSULT: {
      description: "Follow up session",
      title: "Follow up session"
    },
    INITIAL_CONSULT: {
      description: "Rounded to nearest 5 minute for time on zoom",
      title: "Initial session"
    },
    INITIAL_CONSULT_DOCUMENTATION: {
      description: "IC assessment, roadmap, synopsis and internal notes",
      title: "Initial session documentation"
    },
    MANDATED_REPORTING: {
      description: "APS/CPS",
      title: "Mandated reporting"
    },
    MISCELLANEOUS: {
      description:
        'Case consultations, outlier situations, catch all. Needs "Notes" section to allow for further explanation',
      title: "Miscellaneous"
    },
    NON_BILLABLE_ADDITIONAL_CONSULTS_DOCUMENTATION: {
      description:
        "All non-billable session summaries, updating synopsis/roadmap/CP. Does not include additional resource time",
      title: "Additional session (3+) documentation"
    },
    NON_BILLABLE_CARE_PLAN_CREATION: {
      description: "All non-billable CP creation time",
      title: "Care Plan creation"
    },
    NON_BILLABLE_CARE_PLAN_UPDATE: {
      description: "CP update time, non-session",
      title: "Care Plan update"
    },
    NON_BILLABLE_CLIENT_MESSAGING: {
      description: "Check-ins, answering simple questions, pleasantries",
      title: "Member messaging"
    },
    NON_BILLABLE_CONCIERGE_SERVICES: {
      description:
        'All non-billable concierge services. Different from resource vetting. Setting up appointments, etc. "the extra mile"',
      title: "Concierge services"
    },
    NON_BILLABLE_INITIAL_CONSULT_DOCUMENTATION: {
      description: "All non-billable IC assessment, Roadmap, Synopsis and Internal Notes",
      title: "Initial session documentation"
    },
    NON_BILLABLE_MISCELLANEOUS: {
      description:
        'All non-billable case consultations, outlier situations, catch all. Needs "Notes" section to allow for further explanation',
      title: "Miscellaneous"
    },
    NON_BILLABLE_NO_SHOW: {
      description: "All non-billable no shows",
      title: "No show"
    },
    NON_BILLABLE_RESOURCE_FINDING_AND_VETTING: {
      description: "All non-billable resourcing finding and vetting",
      title: "Resourcing finding and vetting"
    },
    NON_BILLABLE_SECOND_CONSULT_DOCUMENTATION: {
      description:
        "All non-billable session summaries, updating synopsis/roadmap/CP. Does not include additional resource time",
      title: "Second session documentation"
    },
    NO_SHOW: { description: "All no-shows", title: "No Show" },
    ONGOING_EMAILING: {
      description: "Ongoing emailing and scheduling",
      title: "Ongoing emailing and scheduling"
    },
    ONGOING_RESEARCH: {
      description: "Other research & resources",
      title: "Ongoing research & resources"
    },
    OTHER_WORK: {
      description: "Other member-facing work",
      title: "Other member-facing work"
    },
    RESOURCE_FINDING_AND_VETTING: {
      description: "Resourcing finding and vetting",
      title: "Resourcing finding and vetting"
    },
    SECOND_CONSULT: {
      description: "Rounded to the nearest 5 minute for time on zoom",
      title: "Second session"
    },
    SECOND_CONSULT_DOCUMENTATION: {
      description: "Session summary, updating synopsis/roadmap/CP. Does not include additional resource time",
      title: "Second session documentation"
    },
    b: "B: ",
    billable: "BILLABLE",
    billableOption: "Billable: ",
    nb: "NB: ",
    nonBillable: "NON-BILLABLE",
    nonBillableOption: "Non-billable: "
  },
  timeLogSupportCategories: {
    FINANCIAL: "Financial",
    FUNCTIONAL: "Functional",
    LEGAL: "Legal",
    MEDICAL: "Medical",
    NO_SHOW: "No show",
    SOCIAL: "Social",
    WELLBEING: "Your well-being"
  },
  timeLogSupportKinds: {
    CARE: "Care",
    COMMUNICATIONS_AND_RELATIONSHIPS: "Communications & relationships",
    CRISIS_OR_HOSPITALIZATION: "Crisis or hospitalization",
    DEMENTIA: "Dementia",
    END_OF_LIFE_CARE: "End of life care",
    FOOD_AND_NUTRITION: "Food and nutrition",
    GRIEF_AND_BEREAVEMENT: "Grief & bereavement",
    HEALTH: "Emotional, mental, physical health",
    HEALTH_INSURANCE: "Health insurance",
    HEALTH_PROVIDERS: "Physicians & other health providers",
    HEARING_OR_VISION: "Hearing or vision",
    HOME_CARE: "Home care",
    HOUSING: "Housing",
    MANAGING_SOMEONES_MONEY: "Managing someone else's money",
    MEDICAL_CONDITIONS: "Medical conditions",
    MEDICATION_MANAGEMENT: "Medication management",
    MENTAL_HEALTH: "Mental health",
    MOBILITY: "Mobility",
    NO_SHOW: "No Show",
    OTHER: "Other",
    PAYING_FOR_MEDICAL_CARE: "Paying for medical care",
    PAYING_FOR_NON_MEDICAL_CARE: "Paying for non medical care",
    PLANNING: "Planning",
    PREPARING_DOCUMENTS: "Preparing and reviewing documents",
    QUALITY_OF_LIFE: "Quality of life",
    SAFETY: "Safety",
    SELF_CARE: "Self-care",
    SUBSTANCE_ABUSE: "Substance abuse",
    TRANSPORTATION: "Transportation",
    USING_DOCUMENTS: "Using documents",
    WORK_CONCERNS: "Work-related concerns"
  },
  tool: {
    title: "Complete: {title}"
  },
  tracker: {
    typeCtaOptions: {
      complete_consent_form: "Consent form",
      complete_legal_document_tracker: "Legal documents tracker",
      complete_medication_tracker: "Medication tracker",
      complete_provider_list: "Provider tracker"
    },
    typeOptions: {
      legal_documents: "Legal documents tracker",
      medication_tracker: "Medication tracker",
      provider_tracker: "Provider tracker"
    }
  },
  triggers: {
    kind: {
      always: "Always",
      concern_selected: "Concern selected",
      more_than_three_medications: "More than 3 prescription medications",
      no_consult_scheduled: "No session scheduled",
      no_primary_care_physician: "Does NOT include primary care physician",
      provider_emotional_state_selected:
        "Today, how are you feeling emotionally about helping to care for your loved one?",
      provider_feels_supported_selected:
        "Do you feel supported (by family, friends, or others) in helping your loved one?",
      question_option_selected: "Personalize my experience",
      relationship_selected: "Relationship selected"
    },
    source: {
      always: "Always",
      care_profile: "Care profile",
      consults: "Sessions",
      medication_tracker: "Medication tracker",
      personalize_experience: "Personalize experience",
      provider_list: "Provider list"
    }
  },
  userType: {
    admin: "Admin",
    client: "Member",
    expert: "Care Partner"
  },
  webinar: {
    contactSupport: "Contact support",
    registrationFailed:
      "Sorry, your registration for this event didn't work as expected. You might need to try later, or you can reach out to support.",
    registrationSuccessful:
      "Congrats, you've successfully registered for this live event. You'll receive an email shortly with instructions for joining the meeting. We'll see you at the event.",
    unregistrationFailed:
      "Sorry, canceling your registration for this event didn't work as expected. You might need to try later, or you can reach out to support.",
    unregistrationSuccessful: "You've successfully canceled your registration for this live event."
  },
  worksheet: {
    deleteItem: 'Are you sure you want to delete "{title}"?',
    edit: "Edit title",
    haveQuestions: "Do you have questions or need help?",
    markAsComplete: "Finished? Let {expertFirstName} know",
    markAsIncomplete: "Finished! Uncheck this to let {expertFirstName} know that you are still working on it.",
    messageExpert: "Questions? Message your Care Partner",
    saveCompletedDocument: "Download completed PDF",
    saveEmptyDocument: "Download blank PDF",
    title: "Complete worksheet: {title}"
  }
};
/* eslint-enable @typescript-eslint/naming-convention */

export default models;
